import { useEffect } from "react";
import { useLoginStore } from "./LoginContext";
import { useProgramContext } from "./ProgramContext";
import { IEventBook, IProgramEventReason, IProgramFlag, IProgramScheme, IProgramStatus, IRecallReason } from "../types/Program.types";
var loop: NodeJS.Timeout | null = null;


export default function ContextLoader () {
    let loginState = useLoginStore(state => state);
    let programState = useProgramContext(state => state);
    
    useEffect(() => {
        if (loginState.users.length === 0) {
            return;
        }

        if (loop !== null) {
            clearInterval(loop);
        }

        if (loginState.users.length === 0) return;
        loop = setInterval(() => {
            const now = Date.now();
            for (const u of loginState.users) {
                console.log(u)
                if (u.access_expire + 6*60*1000 < now) {
                    loginState.refresh_token(u.refresh).then((res) => {
                        console.log("Refreshed Token", res.username);
                    }).catch((err) => {
                        console.error("Failed to refresh token", err);
                    });
                }
            }
        }, 300000);

        loginState.authorised_request("GET", "program/flag", undefined, (response) => {
            if (response.status === 200) {
                programState.setFlags(response.flags as IProgramFlag[]);
            } else {
                console.error("Error fetching flags");
            }
        })
        
        loginState.authorised_request( "GET", "program/scheme",undefined, (response) => {
            if (response.status === 200) {
                programState.setSchemes(response.schemes as IProgramScheme[]);
            } else {
                console.error("Error fetching schemes");
            }
        })
        
        loginState.authorised_request("GET", "program/status", undefined, (response) => {
            if (response.status === 200) {
                programState.setStatus(response.statuses as IProgramStatus[]);
            } else {
                console.error("Error fetching statuses");
            }
        })

        loginState.authorised_request("GET", "program/eventReason", undefined, (response) => {
            if (response.status === 200) {
                programState.setEventReasons(response.eventReasons as IProgramEventReason[]);
            } else {
                console.error("Error fetching event reasons");
            }
        })

        loginState.authorised_request("GET", "program/eventBook", undefined, (response) => {
            if (response.status === 200) {
                programState.setEventBooks(response.eventBooks as IEventBook[]);
            } else {
                console.error("Error fetching event books");
            }
        })

        loginState.authorised_request("GET", "program/recallReason", undefined, (response) => {
            if (response.status === 200) {
                programState.setRecallReasons(response.recallReasons as IRecallReason[]);
            } else {
                console.error("Error fetching recall reasons");
            }
        })
    }, [loginState.users])

    useEffect(()=>{

    })

    return null;
}
