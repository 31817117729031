import { useEffect, useState } from 'react'
import { createEditor, Descendant, Node, Transforms, Editor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

export interface EditorProps {
  value: Descendant[],
  setValue?: (value:Descendant[]) => void
  key?: string
  className?: string
  }


export default ({value, setValue, key, className}: EditorProps) => {
    const [editor] = useState(withReact(createEditor()))

    useEffect(() => {
      if (value.length === 0){
        Transforms.delete(editor, {
          at: {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
          },
        });
      }
    }, [value])
    

  return (
  <Slate key={key} editor={editor} initialValue={value} onChange={setValue}>
    <Editable readOnly={setValue === undefined} className={className} />
  </Slate>)
}


export const serialize =(value:Node[]) => {
    return (
      value
        .flatMap((n:Node) => Node.string(n))
        .join('\n')
    )
  }
