import { addMonths, addWeeks, format, startOfISOWeek, startOfMonth, sub} from "date-fns";
import { useEffect, useState } from "react";
import noDefault from "../../shared/noDefault";
import { async_authorised_request, useLoginStore } from "../../context/LoginContext";
import { useLoaderData } from "react-router-dom";
import {  ISMSPopulated } from "../../types/SMS.types";
import { StretchedLink } from "../../shared/StretchedLink";

export default function ReportSMS() {
    const loader_data = useLoaderData() as ISMSPopulated[];
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);

    const [filter, setFilter] = useState({
        sent: false,
        type: "all",
        start: format(new Date(), "yyyy-MM-dd"),
        end: format(new Date(), "yyyy-MM-dd")
    });

    useEffect(() => {
        const start = new Date(filter.start).getTime();
        const end = new Date(filter.end).getTime();

        if (start > end){
            setFilter({...filter, end: filter.start});
            return;
        }

        GetSMS(start, end, async_authorised_request).then(data => setSMS(data));
    }, [filter]);

    const [SMS, setSMS] = useState(loader_data || []);

  return (
    <div className="m-2 px-2 py-1 bg-stone-300 space-y-3">
        <h1 className="text-3xl mx-2 mt-2 font-semibold text-stone-900">SMS</h1>
        <div className="space-y-1">
            <div>
                <h1 className="text-xl font-semibold text-stone-900">Filter Sent</h1>
                <form className="flex flex-row space-x-1 font-semibold relative">
                    {/* <label className="text">Sent</label>
                    <input className="scale-125" type="checkbox" name="sent" id="sent" checked={filter.sent} onChange={e=>setFilter({...filter, sent: e.target.checked})} /> */}
                    {/* <label className="text pl-2">Type</label>
                    <select className="formi" value={filter.type} onChange={e=>setFilter({...filter, type: e.target.value})}>
                        <option value="all">All</option>
                        <option value="reminder">Reminder</option>
                        <option value="appointment">Appointment</option>
                        <option value="message">Message</option>
                    </select> */}
                    {/* <label className="pl-2">Range</label> */}
                    <input type="date" name="start" id="start" value={filter.start} onChange={e => setFilter({...filter, start: e.target.value})} />
                    <label className="italic p-0.5 translate-y-0.5">TO</label>
                    <input type="date" name="end" id="end" value={filter.end} onChange={e => setFilter({...filter, end: e.target.value})} />

                    <button className="bg-stone-400 hover:bg-stone-500 hover:text-white px-2 py-1 rounded-sm" onClick={noDefault(()=>{
                        const month = addMonths(startOfMonth(new Date()), -1);
                        setFilter({...filter, start:format(month, "yyyy-MM-dd"), end:format(sub(addMonths(month, 1), {days:1}), "yyyy-MM-dd")})
                        })}>Last Month</button>
                        
                    <button className="bg-stone-400 hover:bg-stone-500 hover:text-white px-2 py-1 rounded-sm" onClick={noDefault(()=>{
                        const week = addWeeks(startOfISOWeek(new Date()), -1);
                        setFilter({...filter, start:format(week, "yyyy-MM-dd"), end:format(sub(addWeeks(week, 1), {days:1}), "yyyy-MM-dd")})
                        })}>Last Week</button>

                         <button className="bg-stone-400 hover:bg-stone-500 hover:text-white px-2 py-1 rounded-sm" onClick={noDefault(()=>{
                        const today = format(new Date(), "yyyy-MM-dd")
                        setFilter({...filter, start:today, end:today})
                        })}>Today</button>

                    <button className="bg-stone-400 hover:bg-stone-500 hover:text-white px-2 py-1 rounded-sm" onClick={noDefault(()=>{
                        const week = startOfISOWeek(new Date());
                        setFilter({...filter, start:format(week, "yyyy-MM-dd"), end:format(sub(addWeeks(week, 1), {days:1}), "yyyy-MM-dd")})
                        })}>Current Week</button>

                    <button className="bg-stone-400 hover:bg-stone-500 hover:text-white px-2 py-1 rounded-sm" onClick={noDefault(()=>{
                        const month = startOfMonth(new Date());
                        setFilter({...filter, start:format(month, "yyyy-MM-dd"), end:format(sub(addMonths(month, 1), {days:1}), "yyyy-MM-dd")})
                        })}>Current Month</button>
                        

                    <label className="absolute bottom-0 right-0 text-xl"> Total: {SMS.length} </label>
                </form>
                
            </div>
            <hr/>
            <table className="w-full table-fixed ">

                <thead>
                    <tr>
                        <th className="px-2 text-left">Patient</th>
                        <th className="px-2 text-left">Date</th>
                        <th className="px-2 text-left w-3/4">Message</th>
                    </tr>
                </thead>
                <tbody >
                    {SMS.map((sms, i) => (
                        <tr className="text-nowrap relative hover:bg-stone-400" key={i}>
                            <td className="px-2 hover:font-bold"><StretchedLink children={<p> {sms.patient.forename} {sms.patient.surname} </p>} to={`/patient/${sms.patient._id}`} /></td>
                            <td className="px-2">{format(sms.scheduled_at, " HH:mm dd/MM/yy")}</td>
                            <td className="px-2 select-text truncate">{sms.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  );
}

const GetSMS = ( start: number, end:number, request: async_authorised_request ) => {
    
    const search: {start?:string, end?:string} = {}

    if (!isNaN(start)){
        search.start = start.toString();
    }

    if (!isNaN(end)){
        search.end = (end + 86400000).toString();
    }

    const params=  new URLSearchParams(search)
    return request("GET", `sms?${params.toString()}`, undefined).then(data => data.sms);
}

export const ReportSMSLoader = () => {
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    const today = new Date().getTime();
    return GetSMS(today, today + 86400000, async_authorised_request)
}