export interface ICheckBoxProps {
    getState: any[];
    setState: (newState: any[]) => void;
    editable?: boolean
    options: {value: any, key: string}[];
}

export default function CheckBox ({getState, setState, editable, options}: ICheckBoxProps) {
    getState = getState? getState : [];
    return (
        <div>
            {options.map((option, index) => {
                return (
                    <label key={index} className='block' onClick={editable?(e) => {
                        e.preventDefault();
                        if (getState.includes(option.value)) {
                            setState(getState.filter((id) => id !== option.value))
                        } else {
                            setState([...getState, option.value])
                        }
                    }:undefined}>
                         <input readOnly className='pointer-events-none' checked={getState.includes(option.value)} type='checkbox'/> 
                         {option.key} 
                    </label>
                )
            })}
        
            
        </div>
    );
}



// export default function CheckBox({ getState, setState, editable, options, is_invalid }: ICheckBoxProps) {
//     getState = getState ? getState : [];
//     return (
//         <div>
//             {options.map((option, index) => (
//                 <label key={index} className="block relative cursor-pointer" onClick={editable ? (e) => {
//                     e.preventDefault();
//                     const newState = getState.includes(option.value) ? getState.filter((id) => id !== option.value) : [...getState, option.value];
//                     setState(newState);
//                 } : undefined}>
//                     <input type="checkbox" className="sr-only" readOnly checked={getState.includes(option.value)} />
//                     <div className={`w-4 h-4 inline-flex mr-1 rounded border ${is_invalid ? 'border-red-500 bg-red-500' : 'border-stone-600'} ${getState.includes(option.value) ? 'bg-teal-500 border-stone-600' : ''}`}></div>
//                     {option.key}
//                 </label>
//             ))}
//         </div>
//     );
// }