import { Params, useLoaderData } from "react-router-dom";
import { IResponseData, useLoginStore } from "../context/LoginContext";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useNavbarStore } from "../context/NavbarContext";
import Editor, { serialize } from "../shared/Editor";
import { IPatientNotes } from "../types/Program.types";
import { Node } from 'slate';
import { format } from "date-fns";
import { GetPatientNav } from "../patientHomepage/patientNav";

function PatientNotes () {
  const data = useLoaderData() as {status: number, error: string} | {notes: string[], patient:any};
  if (data && 'error' in data) {
    return (
      <div>
        <h1> Error </h1>
        <p> {data.error} </p>
      </div>
    )
  }

  const authorised_request = useLoginStore(state => state.authorised_request);
  const [notes, setNotes] = useState<IPatientNotes[]>(data.notes as unknown as IPatientNotes[])
  const [errors, setErrors] = useState([] as string[]);
  const [transmitting, setTransmitting] = useState(false);
  const setContent = useNavbarStore(state => state.setContent);
  const [search, setSearch] = useState("")
  const [value, setValue] = useState([
    {
      type: "root",
      children: [{ text: "" }]
    }
  ]) as unknown as [Node[], (change:Node[]) => void];
  useEffect(() => {
    setContent(GetPatientNav(data.patient._id, "Notes"))

    return () => {
      setContent([]);
    }
  }, [])
  let update = useCallback((response: IResponseData) => {
    if (response.status === 201) {
      setValue([]);
      setNotes([response.note, ...notes]);
    }else{
      setErrors(response.error.split("; "));
    }
    setTransmitting(false);
  },[notes, value])

  const UndoNote = (n:any) => {
    authorised_request("DELETE", "patient/notes/"+n._id, undefined, (response) => {
      if (response.status === 200) {
        setNotes(notes.filter(note => note._id !== n._id));
        setValue(JSON.parse(n.note_json));
      }else{
        setErrors(response.error.split("; "));
      }
    })
  }

  const renderedNotes = useMemo(() => {
    return notes.filter(n => search.trim().length <= 1 || n.note_plain.toLowerCase().includes(search.toLowerCase())).flatMap((n, i) => <div key={n._id} className="mx-5 m-1 bg-slate-400 rounded-md ">
      <Editor value={JSON.parse(n.note_json)} className="px-2 pb-2 font-semibold text-xl"/> 
      <p className="italic pb-1 px-1"> {format(new Date(n.created_at), "dd/MM/yy HH:mm:ss")} - {n.created_by?`${n.created_by.forename} ${n.created_by.surname}` :"SYSTEM"} {(i===0 && n.created_at>Date.now()-60*600*600) && <button className="text-red-500 float-right m-2" onClick={e=>{e.preventDefault();UndoNote(n)}}>Undo</button>} </p>
    </div>)
  }, [notes, search])

  return (
    <div>
      <h1 className="text-2xl m-2"> Notes - {data.patient.forename}, {data.patient.surname} </h1>
      <Editor value={value} setValue={setValue} className="border border-gray-500 m-2 px-2 pb-2 text-xl min-h-32" />
      <div className="w-1/5 m-2">
        {errors && <div> {errors.map((e,i) => <p key={i}> {e} </p>)} </div>}
      <button disabled={transmitting || serialize(value).trim().length === 0} className="btn mx-auto rounded-md justify-center m-2 text-red-500 text-xl" onClick={e => {
        setTransmitting(true);
        e.preventDefault()
        e.stopPropagation()
        authorised_request("POST", "patient/notes/"+data.patient._id, {note_json: JSON.stringify(value), note_plain:serialize(value)}, update);
      }}> Save </button>
      </div>
      <div className="m-2">
        <input type="text" className="rounded-md border border-gray-500" value={search} onChange={e => setSearch(e.target.value)} placeholder="Search Notes" />
      </div>
      <div> {renderedNotes} </div> 
    </div>
  );
}

export default PatientNotes;
export async function PatientNotesLoader({params}: {params: Params<string>}){
  const {async_authorised_request} = useLoginStore.getState();
  if (!async_authorised_request) {
    return {status: 500, error: "Not logged in"};
  }

  if (!params.id) {
    return({status: 500, error: "No patient id provided"});
  }

  return Promise.all([
    async_authorised_request("GET", "patient/lean/"+params.id, undefined),
    async_authorised_request("GET", "patient/notes/"+params.id, undefined),
  ]).then((values) => {
    return {patient: values[0].patient, notes: values[1].notes};
  }).catch((error) => {
    return {status: 500, error: error.message};
  }); 
}

