import { Link, useNavigate } from "react-router-dom";
import {useLoginStore} from "../context/LoginContext";
import { useNavbarStore } from "../context/NavbarContext";
import { useEffect } from "react";

export default function Navbar () {
    const {users, logout} = useLoginStore((state) => state);
    const submenuLinks = useNavbarStore((state) => state.content);
    const patient_history = useNavbarStore((state) => state.patient_history);
    const navigate = useNavigate();

    useEffect(() => {
        if (users.length === 0) {
            navigate("/login");
        }
    }, [users]);
    return (
        <>
        <nav key={"/diaryTwo"} className={window.location.pathname==="/diary"?"sticky top-0 z-50":"z-50 relative"}>
        <Link to="/"><h1 className="text-4xl p-2"> Visual<span className="text-teal-500">Aspect</span> </h1></Link>
         
        <div>
            <span className="group relative">
                <Link to="/patient/search">
                    <button className="px-5 py-2 mx-0.5 text-xl bg-stone-600 hover:bg-stone-700 group-hover:bg-stone-700 hover:text-zinc-100"> Patient </button>
                </Link>
                <div className="absolute hidden group-hover:block z-50 w-full px-0.5">
                    {patient_history.map(pl => 
                        <Link key={pl.id} to={"patient/"+pl.id}>
                            <button key={pl.id} className="mx-auto w-full py-2 text-center text-sm bg-stone-600 hover:bg-stone-700 hover:text-zinc-100"> {pl.name} </button>
                        </Link>)}
                        <Link to="/patient/new">
                            <button className="w-full mx-auto py-2 text-center text-nowrap text-sm border-2 border-teal-700 bg-teal-700 hover:bg-teal-800 hover:text-zinc-100"> New Patient </button>
                        </Link> 
                    </div>
            </span>
            
            <span>
                <Link to="/diary">
                    <button className="px-5 py-2 mx-0.5 text-xl bg-stone-600 hover:bg-stone-700 hover:text-zinc-100"> Appointments </button>
                </Link>
                {/* <Link to="/reminders">
                    <button className="px-5 py-2 mx-0.5 text-xl bg-stone-600 hover:bg-stone-700 hover:text-zinc-100"> Reminders </button>
                </Link> */}
                <Link to="/report">
                    <button className="px-5 py-2 mx-0.5 text-xl bg-stone-600 hover:bg-stone-700 hover:text-zinc-100"> Reports </button>
                </Link>
            </span>
        </div>
    

        {/* <div className="">
            {users.map(u => {
                return <button key={u.token} className="align-middle px-5 py-2 mx-3 text-xl rounded-md bg-stone-700 hover:bg-stone-700 hover:text-zinc-100"> {u.name} </button>
            })}
        </div> */}

        {users.length > 0 ? <button key="true" onClick={() => logout()} className="ml-auto px-5 py-2 mx-3 text-xl rounded-md bg-stone-700 hover:bg-stone-700 hover:text-zinc-100"> Logout </button> : <Link to="/login" className="ml-auto align-middle"><button key={"false"} className="px-5 py-2 mx-3 text-xl rounded-md bg-stone-700 hover:bg-stone-700 hover:text-zinc-100"> Login </button></Link>}
    </nav>
        {submenuLinks.length > 0 && <nav key={"/diary"} className={window.location.pathname==="/diary"?"p-1 sticky top-15 -translate-y-1 z-20":"p-1 pb-1.5 sticky z-10"} onClick={_=>{window.scrollTo({top:0, left:0, behavior:"smooth"})}}>{submenuLinks}</nav>}
        </>
   
    );
}
