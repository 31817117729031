import { create } from "zustand";
import { IEventBook, IProgramEventReason, IProgramFlag, IProgramScheme, IProgramStatus, IRecallReason } from "../types/Program.types";

export interface IProgramContext {
    flags: IProgramFlag[];
    setFlags: (flags: IProgramFlag[]) => void;

    schemes: IProgramScheme[];
    setSchemes: (schemes: IProgramScheme[]) => void;

    status: IProgramStatus[];
    setStatus: (status: IProgramStatus[]) => void;

    eventReasons: IProgramEventReason[];
    setEventReasons: (eventReasons: IProgramEventReason[]) => void;

    eventBooks: IEventBook[];
    setEventBooks: (eventBooks: IEventBook[]) => void;

    recallReasons: IRecallReason[];
    setRecallReasons: (recallReasons: IRecallReason[]) => void;
}

export const useProgramContext = create<IProgramContext>((set) => ({
    flags: [],
    setFlags: (flags: IProgramFlag[]) => set({ flags }), 

    schemes: [],
    setSchemes: (schemes: IProgramScheme[]) => set({ schemes }),

    status: [],
    setStatus: (status: IProgramStatus[]) => set({ status }),

    eventReasons: [],
    setEventReasons: (eventReasons: IProgramEventReason[]) => set({ eventReasons }),

    eventBooks: [],
    setEventBooks: (eventBooks: IEventBook[]) => set({ eventBooks }),

    recallReasons: [],
    setRecallReasons: (recallReasons: IRecallReason[]) => set({ recallReasons }),
}));
