import { useState } from "react";
import "../context/LoginContext";
import { useLoginStore } from "../context/LoginContext";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';

export default function Login () {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<{ username: string, password: string, error: string, button_text:string }>({ username: '', password: '', error: "", button_text:"Login" });
  const login = useLoginStore((state) => state.login);
  const login_google = useLoginStore((state) => state.login_google);

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!button_valid){
      return
    }
    setFormState({...formState, button_text:"Loading..."});
    
    login(formState.username, formState.password).then(()=>{
      navigate('/');
    }).catch(errorCode => {
      if (errorCode === 403 || errorCode === 429){
        setFormState({...formState, error: "Too many attempts. Please wait.", button_text:"Error"});
        setTimeout(()=>{setFormState({...formState, error: "", button_text:"Login"})}, 300000);
        return;
      }
      if (Math.floor(errorCode/100) === 5){
        setFormState({...formState, error: "Server Error", button_text:"Error"});
        setTimeout(()=>{setFormState({...formState, error: "Server Error", button_text:"Login"})}, 5000);
        return;
      }
      setFormState({...formState, error: "Incorrect Username or Password", button_text:"Error"});
    });
  }

  const button_valid = formState.password.length > 8 && formState.username.length > 2 && formState.button_text !== "Loading..." && formState.button_text !== "Error";

  return (
    <div className="grid place-items-center min-h-screen">
      <div className="white-card center p-2 pb-4 w-full max-w-md">
          <h1 className="text-2xl font-semibold mx-2 text-center"> Login </h1>
          <hr className="m-3 border-spacing-y-0 border border-emerald-600"/>

          {formState.error && <div className="text-red-600 text-lg font-semibold text mx-auto text-center p-0 m-1"> 
            <p className="flash">{formState.error}</p>
          </div>}
          <div className="w-full flex center">
            <GoogleLogin           
          onSuccess={credentialResponse => {
            login_google(credentialResponse.credential as string).then(()=>{
              navigate('/');
            }).catch(errorCode => {
              if (errorCode === 403 || errorCode === 429){
                setFormState({...formState, error: "Too many attempts. Please wait.", button_text:"Error"});
                setTimeout(()=>{setFormState({...formState, error: "", button_text:"Login"})}, 300000);
                return;
              }
              if (Math.floor(errorCode/100) === 5){
                setFormState({...formState, error: "Server Error", button_text:"Error"});
                setTimeout(()=>{setFormState({...formState, button_text:"Login"})}, 5000);
                return;
              }
              setFormState({...formState, error: "Invalid Google Account", button_text:"Error"});
            });
          }}
          onError={() => {
            setFormState({...formState, error: "There was an error", button_text:"Error"});
            setTimeout(()=>{setFormState({...formState, button_text:"Login"})}, 5000);
          }}
            />
          </div>

          <form onSubmit={submit} className="px-5">


            <label className="block font-semibold text-gray-700"> Username </label>
            <input value={formState.username} onChange={e=>{setFormState({...formState, username:e.target.value, button_text: (formState.button_text === "Error"?"Login":formState.button_text)})}} type="username" className="mt-1 p-2 w-full border border-gray-300 rounded-md" placeholder="Username" />
            <label className="block font-semibold text-gray-600"> Password </label>
            <input value={formState.password} onChange={e=>{setFormState({...formState, password:e.target.value, button_text: (formState.button_text === "Error"?"Login":formState.button_text)})}} type="password" className="mt-1 p-2 w-full border border-gray-300 rounded-md" placeholder="Password" />            
            <button disabled={!button_valid} type="submit" className="mt-4 w-full bg-emerald-600 disabled:hover:bg-emerald-400 disabled:bg-emerald-400 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded"> {formState.button_text} </button>
          </form>
      </div>
    </div>
  );
}
