import { Link } from "react-router-dom";

export function StretchedLink({ children, to, onclick }: { children: React.ReactNode, to: string, onclick?: (e:any) => VideoEncoderBitrateMode }){
    return <Link 
    className="overflow-hidden" 
    to={to} 
    onClick={onclick}
>
    <span className="absolute inset-0 font-bold opacity-0 hover:opacity-100" aria-hidden="true"></span>
    {children}
</Link>} 

export function StretchedAction({ children, onclick }: { children: React.ReactNode, onclick?: (e:any) => void }){
    return <a 
    className="overflow-hidden" 
    onClick={onclick}>
    <span className="absolute inset-0 font-bold opacity-0 hover:opacity-100" aria-hidden="true"></span>
    {children}
</a>}