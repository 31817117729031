import { useEffect, useState } from "react";
import Spinner from "./SpinnerBlock.svg"
import SpinnerRed from "./SpinnerBlockRed.svg"

export interface ISecureImageProps {
    id: string;
    alt: string;
    secure: (path: string, dimensions?: {x:number, y:number}) => Promise<Blob>;
    className?: string;
    style?: React.CSSProperties;
    dimensions?: {x:number, y:number};
}

export default function SecureImage({id, alt, secure, className, style, dimensions}: ISecureImageProps) {
    const [data, setData] = useState<Blob | null>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        secure(id, dimensions).then((res) => {
            setData(res);
        }).catch((error) => {
            console.log(error)
            setError(true);
        })
    },[id])
    
    if (data !== null){
        return <img key={id}  src={URL.createObjectURL(data)} alt={alt} className={className} style={style}/>
    }

    return <img key={id} src={error?SpinnerRed:Spinner} alt={alt} className="h-20 w-20 mx-auto" style={style}/>
}