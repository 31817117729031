import { IProgramEventReason } from "../types/Program.types";

export interface IReasonCheckBoxProps {
    getState: IProgramEventReason[];
    setState: (newState: IProgramEventReason[]) => void;
    editable?: boolean
    options: IProgramEventReason[];
}


export default function ReasonCheckBox ({getState, setState, editable, options}: IReasonCheckBoxProps) {
    return (
        <div> 
            {options.map((option, index) => {
                return (
                    <label key={option._id} htmlFor={"Reason"+index} className={'block cursor-pointer'+((getState.length > 0 && getState[0]._id == option._id)?" font-bold":"")} onClick={editable?(e) => {
                        e.preventDefault();
                        if (getState.findIndex((rsn) => rsn._id === option._id) > -1) {
                            setState(getState.filter((rsn) => rsn._id !== option._id))
                        } else {
                            setState([...getState, option])
                        }
                    }:undefined}>
                         <input readOnly id={"Reason"+index} className='mr-1 ml-0.5 pointer-events-none' checked={getState.findIndex((rsn) => rsn._id === option._id) > -1} type='checkbox'/> 
                         {option.name} 
                         {editable && <button className='float-left' onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const index = getState.findIndex((rsn) => rsn._id === option._id);
                            if (index > -1) {
                                getState[index] = getState[0];
                                getState[0] = option;
                                setState([...getState])
                            } else {
                                setState([option, ...getState])
                            }
                         }} > 📌 </button>}
                    </label>

                )
            })}
        
            
        </div>
    );
}