import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../context/LoginContext";
import { IPopulatedPatient } from "../types/populated.types";
import { ITransaction } from "../types/Transaction.types";
import { useEffect, useState } from "react";
import { useNavbarStore } from "../context/NavbarContext";
import { GetPatientNav } from "../patientHomepage/patientNav";
import CreateTransaction from "./CreateTransaction";
import noDefault from "../shared/noDefault";
import { format } from "date-fns";
import { StretchedAction } from "../shared/StretchedLink";
import ViewTransaction from "./ViewTransaction";

export default function PatientBalance () {
    const {patient, transactions} = useLoaderData() as {patient: IPopulatedPatient, transactions: ITransaction[]};
    const [tracs, setTracs] = useState<ITransaction[]>(transactions);
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);
    const setContent = useNavbarStore(state => state.setContent);
    const [createTransaction, setCreateTransaction] = useState<Partial<ITransaction> | null>();
    const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);
    const [viewTransaction, setViewTransaction] = useState<ITransaction | null>(null);

    useEffect(() => {   
        if (patient && patient._id){
            setContent(GetPatientNav(patient._id, "Balance"));
        }
        return () => {
            setContent([]);
        }
    }, [patient]);
    
    let balance:number[] = []
    let runningTotal:number = 0
    for (let i = tracs.length-1; i >=0 ; i--){
        runningTotal += tracs[i].amount * tracs[i].sign
        balance.push(runningTotal)
    }
    balance.reverse()

    const currentBalance = tracs.reduce((total, action) => total + action.amount*action.sign, 0)

    return (
        <div>            
            <h1 className="text-3xl mb-2">{patient.forename} {patient.surname} - Balance</h1>

            <div className="flex flex-col w-11/12 mx-auto items-center max-w-screen-lg p-1.5 bg-stone-300 rounded">

                <h2 className="text-2xl mb-2 rounded-sm pt-1 relative flex items-center">Current Balance: <span className={currentBalance !== 0 && (currentBalance > 0 ? "bg-emerald-400 px-2 py-0.5" : "bg-red-400 px-2 py-0.5") || "p-2"}>{PrettyPence(currentBalance, true)}</span></h2>

                <div className="">
                    <div className="float-right gap-x-2 flex flex-row">
                        <button onClick={noDefault(()=>{setSelectedTransaction(null);setCreateTransaction({type:"Payment"})})} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">Payment</button>
                        <button onClick={noDefault(()=>setSelectedTransaction(selectedTransaction?null:"Refund"))} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">{selectedTransaction==="Refund"&&"Cancel "}Refund</button>
                        <button onClick={noDefault(()=>{setSelectedTransaction(null);setCreateTransaction({type:"Charge"})})} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">Charge</button>
                        <button onClick={noDefault(()=>{setSelectedTransaction(null);setCreateTransaction({type:"Credit"})})} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">Credit</button>
                        <button onClick={noDefault(()=>setSelectedTransaction(selectedTransaction?null:"Discount"))} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">{selectedTransaction==="Discount"&&"Cancel "}Discount</button>
                        <button onClick={noDefault(()=>setSelectedTransaction(selectedTransaction?null:"Write-Off"))} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">{selectedTransaction==="Write-Off"&&"Cancel "}Write Off</button>
                    </div>
                </div>
         
                
                <table className="w-full divide-y divide-gray-200 text-center table-fixed">
                    <thead>
                        <tr className="w-full">
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider">Type</th>
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider">Date</th>
                            <th className="px-6 w-1/6 md:w-3/6 py-3 font-semibold uppercase tracking-wider">Description</th>
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider whitespace-nowrap">Amount (£)</th>
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider whitespace-nowrap">{selectedTransaction !== null ? "Select" : "Balance (£)"}</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {tracs.filter(t => 
                            selectedTransaction === null || 
                            (t.type==="Payment" && selectedTransaction==="Refund") ||
                            (t.type==="Charge" && selectedTransaction==="Discount") ||
                            ((t.type==="Charge") && selectedTransaction==="Write-Off")
                        ).map((transaction, index) => {
                            return (
                                <tr key={transaction._id} className="relative hover:cursor-pointer hover:bg-gray-100" onClick={selectedTransaction === null? noDefault(()=>setViewTransaction(transaction)):undefined}>
                                    <td>{transaction.type}</td>
                                    <td>{format(transaction.created_at, "dd/MM/yy HH:mm")}</td>
                                    <td className="overflow-ellipsis overflow-hidden text-nowrap">{transaction.description}</td>
                                    <td className="pr-4 text-right">{PrettyPence(transaction.amount)}</td>
                                    {selectedTransaction !== null ? <td><StretchedAction onclick={noDefault(()=>{setCreateTransaction({type:selectedTransaction as any, transaction})})}>Select</StretchedAction></td>:<td className="pr-4 text-right">{PrettyPence(balance[index], true)}</td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {createTransaction?.type && <div onClick={noDefault(()=>setCreateTransaction(null))} className="fixed top-0 left-0 bg-white bg-opacity-40 w-full h-screen overflow-clip flex items-center justify-center shadow-inner">
                    <CreateTransaction 
                        selectedTransaction={createTransaction.transaction} 
                        async_authorised_request={async_authorised_request} 
                        currentBalance={tracs.reduce((total, action) => total + action.amount*action.sign, 0)} 
                        patient={patient} type={createTransaction.type} 
                        createTransaction={(newTrac)=>{setTracs([newTrac, ...tracs.map(t => {
                            if (newTrac.transaction){
                                if (t._id === newTrac.transaction as any){
                                    return {...t, modifiers:[...(t.modifiers||[]), newTrac]};
                                }else{
                                    return t;
                                }
                            }else{
                                return t;
                            }
                        })
                    ]); setCreateTransaction(null); setSelectedTransaction(null)}} />
                </div>}
                
                {viewTransaction && 
                    <div 
                        onClick={noDefault(()=>setViewTransaction(null))} 
                        className="fixed top-0 left-0 w-full h-screen overflow-clip flex items-center justify-center bg-white bg-opacity-40 ">
                    <ViewTransaction transaction={viewTransaction} parent={viewTransaction.transaction?tracs.find(t => t._id === viewTransaction.transaction as any):undefined} />
                </div>}


            </div>
        </div>
    );
}

export function PrettyPence(amount: number, sign: boolean = false){
    if (amount === 0) return "0.00";
    return `${(sign && amount>0 && "-") || ""}${Math.trunc(Math.abs(amount)/100)}.${(Math.abs(amount%100)).toString().padStart(2,"0")}`
}

export function PatientBalanceLoader({params}: {params:Params<string>}){
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    if (!async_authorised_request){
        console.log("Not logged in")
        return {status:401, error: "Not logged in"};
    }

    return Promise.all([
        async_authorised_request("GET", `patient/lean/${params.id}`, undefined),
        async_authorised_request("GET", `patient/transactions/${params.id}`, undefined),
    ]).then(([patient, transaction]) => {
        return {patient:patient.patient, transactions:transaction.transactions};
    }).catch((error) => {
        console.log(error)
        return error;

    })
}