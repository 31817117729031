import { useEffect, useState } from "react";
import { MixedReminder } from "../types/Reminder.types";
import { add, format, max, min, sub } from "date-fns";
import { IPopulatedPatient } from "../types/populated.types";
import { APIMethod } from "../shared/api";

export interface IReminderCreateProps {
    reminder: Partial<MixedReminder>;
    createReminder: (reminder: any) => void;
    async_authorised_request: ( method: APIMethod, url: string, data?: any) => Promise<any>;
}

export function ReminderCreate ({reminder, async_authorised_request, createReminder}: IReminderCreateProps) {
    const [create, setCreate] = useState<any>({kind:"message",message:"", title:"", ...reminder, due_at:new Date(reminder.due_at || new Date()).toISOString().split("T")[0], events:[]})
    const [patient, setPatient] = useState<{id:string, dob:string, previous_search:string, forename:string, surname:string, patients:IPopulatedPatient[]}>({id:"", dob:"", forename:"", surname:"", patients:[], previous_search:""})
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (create.patient){
            if (create.patient.forced) return;
            async_authorised_request( "GET", "event?patient_id="+create.patient._id,undefined).then((data) => {
                setCreate({...create, events:data.events, appointment:data.events.length>0?data.events[0]._id:create.event});
            }).catch((error) => {
                setErrors([error.error]);
            })
        }
    }, [create.patient])

    useEffect(() => {
        let query = new URLSearchParams();
        if (patient.id && parseInt(patient.id, 10)) {
            query.append("patient_id", patient.id);
        }
        if (patient.forename && patient.forename.length >= 2) {
            query.append("forename", patient.forename);
        }
        if (patient.surname && patient.surname.length >= 2) {
            query.append("surname", patient.surname);
        }
        const newDate = new Date(patient.dob).getTime();
        if (!isNaN(newDate)) {
            query.append("dob", newDate.toString());
        }

        if (query.size !== 0 && query.toString() !== patient.previous_search){
            async_authorised_request("GET", "patient/details/search?" + query.toString(), undefined).then((data) => {
                setPatient({...patient, patients:data.patients, previous_search:query.toString()});
            }).catch((error) => {
                setErrors([error.error]);
            })            
        }
    }, [patient.forename, patient.surname, patient.dob, patient.id])
    return (
        <div>
            <h1 className="text-2xl">Create Reminder</h1>
        
            {errors.length > 0 && (<div className="flex flex-col">
                {errors.map((e) => <p key={e} className="text-red-500">{e}</p>)}
            </div>)}

            {create.patient?
            <form>
                <label className="block mt-1 text-lg">Patient:</label>
                <input readOnly className="block p-1 mb-1 cursor-pointer"  onClick={_ => (!create.patient.forced)&&setCreate({...create, patient:null})} value={create.patient?`${create.patient.forename} ${create.patient.surname}`:""} />
                
                <label className="block mt-1 text-lg">Type:</label>
                <select className="block p-1 mb-1 text-xl" value={create.kind} onChange={(e) => setCreate({...create, kind:e.target.value as "appointment"|"recall"|"message"})}>
                    <option value="appointment">Appointment</option>
                    <option value="recall">Recall</option>
                    <option value="message">Message</option>
                </select>

                <label className="block mt-1 text-lg">Due:</label>
                <input type="date" className="block p-1 mb-1" value={create.due_at} onChange={(e) => {
                    const date = e.target.valueAsDate
                    if (!date){
                        setCreate({...create, due_at:e.target.value})
                    }else{
                        setCreate({...create, due_at:min([max([date, sub(new Date(), {years:1})]), add(new Date(), {years:5})]).toISOString().split("T")[0]})
                    }
                }} />

                {create.kind === "message" && (<div>
                    <label className="block mt-1 text-lg">Title:</label>
                    <input className="block p-1 mb-1 w-full" value={create.title} onChange={(e) => setCreate({...create, title:e.target.value})} />
                    <label className="block mt-1 text-lg">Message:</label>
                    <textarea className="block p-1 mb-1 w-full" value={create.message} onChange={(e) => setCreate({...create, message:e.target.value})} />
                </div>)}

                {(create.kind === "appointment" || create.kind === "recall")&& (<div>
                    <label className="block mt-1 text-lg">Appointment:</label>
                    <select className="w-full text-center text-lg" value={create.appointment} onChange={e => setCreate({...create, appointment:e.target.value})}>
                        {create.events && (create.events.length > 0? create.events.map((e:any) => <option key={e._id} value={e._id}>{e.title} - {format(new Date(e.start), "dd/MM/yy HH:mm")}</option>):<option disabled selected> No Events </option>)}
                    </select>
                </div>
               )}

                <button className="mt-3 bg-stone-500 px-2 font-semibold text-white" onClick={e=> {
                    e.preventDefault();
                    setErrors([]);
                    async_authorised_request("POST", "reminder/new/"+create.kind, {
                        patient: create.patient._id,
                        due_at: create.due_at,
                        kind: create.kind,
                        title: create.title,
                        message: create.message,
                        appointment: create.appointment
                    }).then((data) => {
                        createReminder(data.reminder);
                    }).catch((res) => {
                        setErrors(res.error.split("; "));
                    })
                }}>Create</button>
            </form>:<form>
                    <label htmlFor="patient_id">Patient Id:</label>
                    <input type="text" id="patient_id" value={patient.id} onChange={(e) => setPatient({...patient, id:e.target.value})} />

                    <label htmlFor="dob">DOB:</label>
                    <input type="date" id="dob" value={patient.dob} onChange={(e) => setPatient({...patient, dob:e.target.value})} />

                    <label htmlFor="forename">Forename:</label>
                    <input type="text" id="forename" value={patient.forename} onChange={(e) => setPatient({...patient, forename:e.target.value})} />

                    <label htmlFor="surname">Surname:</label>
                    <input type="text" id="surname" value={patient.surname} onChange={(e) => setPatient({...patient, surname:e.target.value})} />

                    <div>
                        {patient.patients.map((p) => <p key={p._id} className="cursor-pointer" onClick={() => setCreate({...create, patient:p})}>{p.forename} {p.surname} ({p.patient_id})</p>)}
                    </div>
                
                </form>}
        </div>
    );
}
