import { Link } from "react-router-dom";

export function ReportIndex(){
    return (
        <div className="bg-stone-300 p-2 m-2 rounded-sm">
            <h1 className="text-3xl">Report Index</h1>
            <hr className="p-1 border-stone-400 mt-1"/>
            <div className="p-2flex flex-row flex-wrap space-x-4 space-y-4">
                <Link to="/report/sms">
                    <button className="bg-stone-500 hover:bg-stone-600 w-20 h-10 text-white text-xl rounded-sm">SMS</button>
                </Link>

                <Link to="/report/recall">
                    <button className="bg-stone-500 hover:bg-stone-600 w-20 h-10 text-white text-xl rounded-sm">Recall</button>
                </Link>
            </div>
        </div>
    );
}