import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { create } from "zustand";

export interface INavbarContext {
    content: ReactNode[];
    setContent: (content: ReactNode[]) => void;
    patient_history: {name:string, id:string}[];
    add_patient_history: (path: {name:string, id:string}) => void;
}

export const useNavbarStore = create<INavbarContext>((set) => ({
    content: [],
    setContent: (content: ReactNode[]) => set({content}),
    patient_history: [],
    add_patient_history: (patient: {name:string, id:string}) => set(state => ({patient_history: [patient,...state.patient_history.filter((p,i) => i<6 && p.id !== patient.id)]}))
}));


export const NavbarLink = ({name, path, action, className}: {name: string, path?: string,className?:string, action?: (e: any) => void}) => {
    if (!path){
        return (
            <a key={name+path}><button className={className?className:"px-5 py-0.5 mx-0.5 text bg-stone-700 hover:bg-stone-700 hover:text-zinc-100"} onClick={action}> {name} </button></a>
        )
    }
    return (
        <Link key={name+path} to={path}><button className={className?className:"px-5 py-0.5 mx-0.5 text bg-stone-700 hover:bg-stone-700 hover:text-zinc-100"} onClick={action}> {name} </button></Link>
    )
}