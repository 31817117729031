import {  Navigate, Params, useLoaderData } from 'react-router-dom';
import { IPatient } from '../types/Patient.types';
import PatientView from './PatientView';
import { format } from 'date-fns';
import { IResponseData, useLoginStore } from '../context/LoginContext';
import { useProgramContext } from '../context/ProgramContext';

export default function PatientHomepage () {
  const data = useLoaderData() as (IPatient | {status:number, error:string});
  const program = useProgramContext(state => state);
  // Handled Error
  if ("error" in data) {
    if (data.status === 500) {
      return (<Navigate to="/patient/search"/>)
    }

    if (data.status === 303) {
      return (
        <PatientView patient={{
            _id: null,
            patient_id: "", 
            forename: "", 
            surname: "", 
            dob: "", 
            status: program.status[0]?._id || "", 
            scheme: [program.schemes[0]?._id || ""].filter((s) => s), 
            sex: "", 
            gender: "", 
            knownAs: "", 
            social: [], 
            address: [{streetOne: "", streetTwo: "", city: "", postcode: "", active: false}],
            email: [{email: "", active: false}], 
            telephone: [{number: "", active: false, landline: false}], 
            flags: [],
            title: "", 
            last_appointment: null,
            next_appointment: null,
            last_recall: null,
            next_recall: null,
            
            created_at: 0,
            created_by: null,
            deleted_at: null,
            deleted_by: null,
          }} editable={true}/>
      );
    }
    return <Navigate to="/"/>
  }

  data.telephone = data.telephone || [];
  data.email = data.email || [];
  data.social = data.social || [];
  data.flags = data.flags || [];
  data.scheme = data.scheme || [];
  data.address = data.address || [{streetOne: "", streetTwo: "", city: "", postcode: "", active: false}];

  return (
    <PatientView key={data._id} patient={data.dob?{...data, dob: format(new Date(data.dob), "yyyy-MM-dd")}:{...data, dob: ""}} editable={false} />
  );
}

export const PatientHomepageLoader = async ({params}: {params: Params<string>}) => {
  const {authorised_request} = useLoginStore.getState();
  if (!authorised_request) {
    return {status: 500, error: "Not logged in"};
  }

  if (!params.id) {
    return({status: 500, error: "No patient id provided"});
  }

  if (params.id === "new") {
    return {status: 303, error: "New Patient"};
  }

  const result = await (new Promise((resolve) => {
    authorised_request("GET", "patient/details/" + params.id, undefined, (response: IResponseData) => {
      if (response.status === 200) {
        resolve(response.patient as IPatient);
      } else {
        resolve(response);
      }
    });
  }))

  return result;
}