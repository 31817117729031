import { useState } from "react";
import { async_authorised_request } from "../../context/LoginContext";
import noDefault from "../../shared/noDefault";


export interface IProcessModalProps {
    aarequest: async_authorised_request;
    patients: { id: string, reference:string, forename: string, surname:string}[];
    callback: () => void;
}

const mergeFields: any = {
    forename: "{{Patient Forename}}",
    surname: "{{Patient Surname}}",
    reference: "{{Patient Reference}}",

    next_app: "{{Next Appointment Date}}",
    last_visit: "{{Last Visit Date}}",
}

export default function ProcessModal({aarequest, patients, callback}: IProcessModalProps){
    const [form, setForm] = useState({method: "sms", content: "", merge: "forename", content_cursor_position: 0, confirm_process:false, processing:false, preview:""});
    if (patients.length === 0) callback();

    return (<div className="fixed inset-0 flex items-center justify-center-100 h-full top-0 left-0 w-full bg-white bg-opacity-40 overflow-clip" onClick={noDefault(() => {form.processing || callback()})}>

    <div className=" bg-stone-300 p-2 rounded-md w-2/3 mx-auto justify-center center max-w-screen-lg px-5 space-y-2" onClick={noDefault(()=>{setForm({...form, confirm_process:false, preview:""})})}>
        <h1 className="text-2xl"> Process Recall </h1>
        <form className="flex flex-col space-y-0.5">
            <label htmlFor="method" className="m-1">Method:</label>
            <select id="method" disabled={form.processing} className="px-1 py-0.5 rounded-sm bg-stone-50 font-semibold" value={form.method} onChange={e => {setForm({...form, method:e.target.value})}}>
                <option disabled={form.method==="sms"} value="sms">SMS</option>
                <option disabled={form.method==="email"} value="email">Email</option>
                <option disabled={form.method==="letter"} value="letter">Letter</option>
                <option disabled={form.method==="verbal"} value="verbal">Verbal</option>
            </select>
            <label htmlFor="message" className="m-1 mx-">Message:</label>
            <textarea id="message" readOnly={form.processing || !!form.preview} rows={5} onSelectCapture={(e:any) => {form.content_cursor_position = e.target.selectionStart}} className={"px-2 py-1 rounded-sm bg-stone-50 font-semibold " + ( form.preview? "bg-teal-200 border-stone-400":"")} value={!!form.preview ? form.preview : form.content} onChange={e => setForm({...form, content:e.target.value})}></textarea>
            <div className="space-x-1">
                <label htmlFor="mergeFields" className="m-1">Field:</label>
                <select disabled={form.processing} id="mergeFields" className="px-1 py-0.5 rounded-sm bg-stone-50 font-semibold" value={form.merge} onChange={e => {setForm({...form, merge:e.target.value})}}>
                    <option disabled={form.merge==="forename"} value="forename">Forename</option>
                    <option disabled={form.merge==="surname"} value="surname">Surname</option>
                    <option disabled={form.merge==="reference"} value="reference">Reference</option>
                    <option disabled={form.merge==="next_app"} value="next_app">Date: Next Appointment</option>
                    <option disabled={form.merge==="last_visit"} value="last_visit">Date: Last Visit</option>
                </select>
                <button disabled={form.processing } className="px-1 rounded-sm bg-stone-400 hover:bg-stone-500 disabled:bg-stone-400" onPointerDownCapture={noDefault(()=>{})} onClickCapture={noDefault(() => {
                    const merge = mergeFields[form.merge];
                    if (!merge) return;
                    setForm({...form, content: form.content.slice(0, form.content_cursor_position) + merge + form.content.slice(form.content_cursor_position), content_cursor_position: form.content_cursor_position + merge.length});
                })}>Add</button>
            </div>
        </form>
        <hr/>
        <div>
            <table className="w-11/12 table-fixed text-center max-h-48 overflow-y-visible mx-auto">
                <thead>
                    <tr>
                        <th>Reference</th>
                        <th>Forename</th>
                        <th>Surname</th>
                    </tr>
                </thead>
                <tbody>
                    {patients.map((p) => {
                        return <tr key={p.id} className="hover:bg-stone-400 rounded-sm" onClick={()=>{
                            if (form.processing || form.content.trim().length === 0) return;
                            aarequest("POST", "recall/manual/preview", {template: form.content, patient: p.id}).then((res) => {
                                setForm({...form, preview: res.preview});
                            }).catch(console.error)
                        }}>
                            <td>{p.reference}</td>
                            <td>{p.forename}</td>
                            <td>{p.surname}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <button disabled={form.processing|| form.content.trim().length === 0} className={("px-1 rounded-sm text-2xl w-11/12 block mx-auto disabled:bg-stone-400 disabled:text-black " + (form.confirm_process?"bg-red-600 hover:bg-red-700 text-white":"bg-teal-500 hover:bg-teal-600"))} onClick={noDefault(() => {
            if (form.confirm_process){
                setForm({...form, processing:true});
                
            }else{
                setForm({...form, confirm_process:true});
            }
        })}>
            {form.confirm_process?"Confirm":"Process"}
        </button>
    </div>
</div>)
}