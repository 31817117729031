import { IChartPlot } from "../types/Charting.types";

export interface ChartRenderProps {
    charting: IChartPlot[];
    click?: (x:number, y:number) => void;
    mouseMove?: (x:number, y:number) => void;
    className?: string;
}

var penDown = false;
export default function ChartRender({charting, click, mouseMove, className}: ChartRenderProps) {
    return (<svg className={className + (mouseMove?" touch-none":"")} viewBox="0 0 200 90" shapeRendering="geometricPrecision" preserveAspectRatio="xMidYMid meet" 

    onPointerDown={click?e => {
        penDown = true;
        const rect = e.currentTarget.getBoundingClientRect();
        const x = (e.clientX - rect.left)/rect.width*200;
        const y = (e.clientY - rect.top)/rect.height*90;
        click(x, y);
    }:undefined}
    onPointerMove={mouseMove?e => {
        if (!penDown) return;
        const rect = e.currentTarget.getBoundingClientRect();
        const x = (e.clientX - rect.left)/rect.width*200;
        const y = (e.clientY - rect.top)/rect.height*90;
        mouseMove(x, y);
    }:undefined}
    onPointerUp={_=>penDown=false}
    onPointerLeave={_=>penDown=false}
    >
        <defs>
            <radialGradient id="wall" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" style={{stopColor:"#F85", stopOpacity:1}} />
                <stop offset="80%" style={{stopColor:"#F55", stopOpacity:1}} />
            </radialGradient>

            <radialGradient id="macular" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" style={{stopColor:"#a85", stopOpacity:1}} />
                <stop offset="80%" style={{stopColor:"#a55", stopOpacity:1}} />
            </radialGradient>
        </defs>

        <g>
            <circle cx="50" cy="45" r="40" fill="url(#wall)" stroke="#000" strokeWidth="1" />
            <circle cx="50" cy="45" r="4" fill="#ffA" strokeWidth="0" />
            <path d="M 58 60 L 38 30" stroke="#800" strokeWidth=".7" />
            <path d="M 38 60 L 58 30" stroke="#800" strokeWidth=".7" />
            <circle cx="74" cy="45" r="4" fill="url(#macular)" strokeWidth="0" />
        </g>

        <g>
            <circle cx="150" cy="45" r="40" fill="url(#wall)" stroke="#000" strokeWidth="1" />
            <circle cx="150" cy="45" r="4" fill="#ffA" strokeWidth="0" />
            <path d="M 142 60 L 162 30" stroke="#800" strokeWidth=".7" />
            <path d="M 162 60 L 142 30" stroke="#800" strokeWidth=".7" />
            <circle cx="126" cy="45" r="4" fill="url(#macular)" strokeWidth="0" />
        </g>

        <g>
            {charting.map((plot, index) => {
                switch(plot.kind){
                    case "ChartPOI":
                        return <g key={index}><circle cx={plot.x} cy={plot.y} r={plot.size} fill="#00000000" stroke="#000000aa" radius={plot.size/10} /><text dominant-baseline="middle" fontSize={plot.size} fontWeight={700} textAnchor="middle" fill="#000000aa" x={plot.x} y={plot.y}>{plot.count || index}</text></g>
                    case "ChartSuperficial":
                        return <circle key={index} cx={plot.x} cy={plot.y} r={plot.size} fill="#222" />
                    case "ChartHemorrhage":
                        return <circle key={index} cx={plot.x} cy={plot.y} r={plot.size} fill="#f00" />
                    case "ChartDeep":
                        return <rect key={index} x={plot.x-plot.size} y={plot.y-plot.size} width={plot.size*2} height={plot.size*2} fill="#222" />                    }    
            })}
        </g>
    </svg>)

}