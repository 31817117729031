import { ChangeEvent, useEffect, useState } from "react";
import { format } from "date-fns/format";
import { IPatient } from "../types/Patient.types";
import { IEventBook, IProgramEventReason } from "../types/Program.types";
import ReasonCheckBox from "./ReasonCheckBox";
import { IResponseData } from "../context/LoginContext";
import { add, formatDate } from "date-fns";
import { IReminderAppointment } from "../types/Reminder.types";
import { APIMethod } from "../shared/api";
import { sub } from "date-fns/sub";

export interface IEventModalProps {
    event: IModalEvent;
    setEvent: (newEvent: IModalEvent| null) => void;
    getErrors?: string[];
    eventReasons: IProgramEventReason[];
    eventBooks: IEventBook[];
    authorised_request: (method: APIMethod, url: string,  body: any, callback: (response: IResponseData) => void) => void;
}

export interface IModalEvent{
    _id?: string;
    patient_ref: string | null;
    patient: string | null;
    start: number;
    end: number;
    title: string;
    text: string;
    event_reasons: IProgramEventReason[];
    book_id: string;
    reminders_sms: {send_at:number, sms:string[], is_processed:boolean}[];
    reminders: (IReminderAppointment | {is_new:true, due_at:Date, _id:string})[];
    delete?: boolean;
}

function isBiggerTime(a: string, b: string) {
    const a_split = a.split(":");
    const b_split = b.split(":");
    if (parseInt(a_split[0], 10) > parseInt(b_split[0], 10)) {
        return true;
    } else if (parseInt(a_split[0], 10) === parseInt(b_split[0], 10)) {
        return parseInt(a_split[1], 10) > parseInt(b_split[1], 10);
    }
    return false;
}

export default function EventModal({ event, setEvent, getErrors,eventReasons, eventBooks, authorised_request }: IEventModalProps) {
    const day = event.start;
    const [edit, setEdit] = useState({
        ...event,
        start: format(event.start, "HH:mm"),
        end: format(event.end, "HH:mm"),
        delete: undefined as boolean | undefined,
    });
    const [patientForm, setPatientForm] = useState({
        id: "",
        forename: "",
        surname: "",
        dob: "",
    } as { id: string; forename: string; surname: string; dob: string });
    const [foundPatients, setFoundPatients] = useState([] as IPatient[]);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setPatientForm({ ...patientForm, [e.target.id]: e.target.value });
    };

    useEffect(() => {
        let query = new URLSearchParams();
        if (patientForm.id && parseInt(patientForm.id, 10)) {
            query.append("patient_id", patientForm.id);
        }
        if (patientForm.forename && patientForm.forename.length >= 2) {
            query.append("forename", patientForm.forename);
        }
        if (patientForm.surname && patientForm.surname.length >= 2) {
            query.append("surname", patientForm.surname);
        }
        const newDate = new Date(patientForm.dob).getTime();
        if (!isNaN(newDate)) {
            query.append("dob", newDate.toString());
        }

        if (query.size !== 0) {
            query.set("limit", "5");
            authorised_request("GET", "patient/details/search?" + query.toString(), undefined, (res) => {
                if (res.status === 200) {
                    setFoundPatients(res.patients);
                }
            })
        }
    }, [patientForm]);

    const handleSave = (e: { preventDefault: () => void }) => {
        e.preventDefault();

        const end_new = new Date(event.end);
        const end_split = edit.end.split(":");
        end_new.setHours(parseInt(end_split[0], 10), parseInt(end_split[1], 10));

        const start_new = new Date(event.start);
        const start_split = edit.start.split(":");
        start_new.setHours(parseInt(start_split[0], 10), parseInt(start_split[1], 10));

        setEvent({ ...event, ...edit, start: start_new.getTime(), end: end_new.getTime() });
    };

    const handleDelete = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setEvent({ ...event, delete: true });
    };

    const handleEditChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        setEdit({ ...edit, [e.target.id]: e.target.value });
    };

    return (
        <div className="fixed z-20 inset-0 flex items-center justify-center" onClick={() => setEvent(null)}>
            <form className="bg-slate-300 p-5 rounded-lg" onClick={(e) => e.stopPropagation()}>
                <h1 className="text-3xl px-2"> Appointment Modal</h1>
                {edit.patient_ref ? (
                    <>
                        <h3 className="text-xl px-2 text-left"> {new Date(event.start).toLocaleDateString()} </h3>



            <div className="grid grid-cols-2 gap-3">
                <div className="mt-4 col-span-2 text-center">
                            {getErrors &&
                                getErrors.map((error, index) => (
                                    <p key={index} className="text-red-500">
                                        {error}
                                    </p>
                                ))}
                        </div>

                        <div className="w-full">
                            <label htmlFor="patient_ref">Patient ID</label>
                            <a 
                                className="cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setEdit({ ...edit, patient_ref: null });
                                }}
                            >
                                <input
                                    type="number"
                                    id="patient_ref"
                                    className="w-full rounded-md pointer-events-none p-2"
                                    value={edit.patient_ref}
                                    disabled
                                />
                            </a>
                        </div>

                        <div className="w-full">
                            <label htmlFor="book_id">Book</label>
                            <select
                                id="book_id"
                                className="w-full rounded-md p-2"
                                onChange={e=> setEdit({...edit, book_id: e.target.value})}
                                value={edit.book_id}
                            >
                                {eventBooks.map((book) => (
                                    <option key={book._id} value={book._id}>
                                        {book.title}
                                    </option>
                                ))}
                            </select> 
                        </div>

                        <div className="w-full">
                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                id="title"
                                className="w-full rounded-md p-2"
                                onChange={handleEditChange}
                                value={edit.title}
                            />
                        </div>

                            <div className="flex flex-row space-x-2">
                            <div className="w-1/2">
                            <label htmlFor="start">Start</label>
                            <input
                                type="time"
                                step={300}
                                id="start"
                                className="w-full rounded-md p-2"
                                onChange={(e) => {
                                    if (isBiggerTime(edit.end, e.target.value)){
                                        setEdit({ ...edit, start: e.target.value })
                                    } else{
                                        const start_new = new Date(event.start);
                                        const start_split = e.target.value.split(":");
                                        const output_start = start_new.setHours(parseInt(start_split[0], 10), parseInt(start_split[1], 10));
                                        const end = formatDate(add(output_start, {minutes:5}), "HH:mm")
                                        setEdit({ ...edit, start: e.target.value, end });
                                    } 
                                }}
                                value={edit.start}
                            />
                        </div>
                        
                        <div className="w-1/2">
                            <label htmlFor="end">End</label>
                            <input
                                type="time"
                                id="end"
                                
                                step={300}
                                className="w-full rounded-md p-2"
                                onChange={(e) => {
                                    if (isBiggerTime(e.target.value, edit.start)){
                                        setEdit({ ...edit, end: e.target.value })
                                    } else{
                                        setEdit({ ...edit, end: edit.start });
                                    } 
                                }}
                                value={edit.end}
                            />
                        </div>
                            </div>
                      

   

                        <div className="w-full col-span-full">
                            <label htmlFor="text">Notes</label>
                            <textarea
                                rows={4}
                                id="text"
                                className="w-full rounded-md p-2"
                                onChange={handleEditChange}
                                value={edit.text}
                            />
                        </div>
      
                        <div className="w-full">
                            <label htmlFor="event_reasons">Reason(s)</label>
                                <ReasonCheckBox editable={true} getState={edit.event_reasons} setState={(newReasons) => {
                                    if (edit.title.trim() === "" && newReasons.length > 0){
                                        setEdit({ ...edit, event_reasons: newReasons, title: newReasons[0].name });
                                    }else{
                                        setEdit({ ...edit, event_reasons: newReasons })
                                    }
                                }} options={eventReasons} />
                        </div>

                        {/* <div className="mt-2 w-full">
                                <label htmlFor="reminders">Reminders</label>
                                {edit.reminders && edit.reminders.map(rem => {
                                    let className = "block p-0 relative";
                                    if ("status" in rem){
                                        if (rem.status === "Overdue"){
                                            className += " bg-red-400";
                                        }
                                        if (rem.status === "Completed"){
                                            className += " bg-green-400";
                                        }

                                        if (rem.is_deleted){
                                            className += " strike-through";
                                        }
                                       
                                    }else {
                                        className += " semi-bold";
                                    }
                                    if ("is_new" in rem){
                                        className += " italic";
                                        return <button key={rem._id} onClick={noDefault(() => setEdit({...edit, reminders: edit.reminders.filter(r => r._id !== rem._id)}))} className={className}>{format(rem.due_at, "dd/MM/yy")}</button>
                                    }
                                    return <Link to={"/reminders?time="+new Date(rem.due_at).getTime()}><button key={rem._id} className={className}>{format(rem.due_at, "dd/MM/yy")}</button> </Link>
                                    })}
                                <button onClick={e => {
                                    e.preventDefault();
                                    const days = parseInt(prompt("How many days before the appointment should the reminder be sent? (e.g. 3)") || "",10);
                                    if (!days || isNaN(days)) return; // purposefully removed the -day check so people cna have follow up recalls (-future)
                                        setEdit({...edit, reminders: edit.reminders? [...edit.reminders, {due_at:sub(new Date(event.start),{days}), is_new:true, _id:new Date().toISOString()}] : [{due_at:sub(new Date(event.start),{days}), is_new:true,_id:new Date().toISOString()}]})
                    

                                }} className="block border-white border-2 px-2 my-1 rounded-md"> New </button>
                        </div> */}
                        <div className="flex-col flex">
                            <label>Reminders</label>
                            <span> <input className="scale-110" type="checkbox" id="sms_reminder" checked={edit.reminders_sms.length > 0}  onChange={_ => {
                                if (edit.reminders_sms.filter(e => !e.is_processed).length === 0){
                                    if (edit.reminders_sms.length > 0){
                                        setEdit({...edit, reminders_sms:[]})
                                    }else{
                                        setEdit({...edit, reminders_sms:[{send_at:sub(day, {days:3}).getTime(), sms:[], is_processed:false}]})
                                    }
                                }
                            }} /> <label htmlFor="sms_reminder">   SMS    </label> </span>
                            <span> <input className="scale-110" type="checkbox" id="letter_reminder"/> <label htmlFor="letter_reminder">Letter </label> </span>
                        </div>

                            

                        </div>
                        <hr className="my-3"/>

                        <div className="col-span-full w-full flex ">
                            {edit._id && (edit.delete ? 
                                <button className="bg-red-500 w-1/3 rounded-lg mx-auto py-1 text-white text-xl" onClick={handleDelete}>Confirm Deletion</button> 
                                    : 
                                <button className="bg-red-500 w-1/3 rounded-lg mx-auto py-1 text-white text-xl text-center" onClick={(e) => {e.preventDefault(); setEdit({...edit, delete:true})}}>Delete</button> 
                            )}

                            <button className="bg-emerald-500 w-1/3 rounded-lg mx-auto text-xl py-1" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className="text-xl px-2 text-center"> Patient Search </h3>

                        <div className="gap-2 mt-4 w-100">
                            <label htmlFor="id">ID</label>
                            <input
                                type="number"
                                id="id"
                                className="w-full rounded-md"
                                onChange={(e) => {
                                    (e.target.value === "" || !isNaN(e.target.valueAsNumber)) &&
                                        setPatientForm({ ...patientForm, id: e.target.value });
                                }}
                                value={patientForm.id}
                            />
                        </div>

                        <div className="gap-2 mt-2 w-100">
                            <label htmlFor="forename">Forename</label>
                            <input
                                type="text"
                                id="forename"
                                className="w-full rounded-md"
                                onChange={handleSearchChange}
                                value={patientForm.forename}
                            />
                        </div>

                        <div className="gap-2 mt-2 w-100">
                            <label htmlFor="surname">Surname</label>
                            <input
                                type="text"
                                id="surname"
                                className="w-full rounded-md"
                                onChange={handleSearchChange}
                                value={patientForm.surname}
                            />
                        </div>

                        <div className="gap-2 mt-2 w-100">
                            <label htmlFor="dob">DOB</label>
                            <input
                                type="date"
                                id="dob"
                                className="w-full rounded-md"
                                onChange={(e) =>
                                    setPatientForm({
                                        ...patientForm,
                                        dob: e.target.value,
                                    })
                                }
                                value={patientForm.dob}
                            />
                        </div>

                        <div className="m-2">
                            {foundPatients &&
                                foundPatients.map((patient) => (
                                    <a
                                        key={patient.patient_id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEdit({ ...edit, patient_ref: patient.patient_id, patient: patient._id});
                                        }}
                                    >
                                        <p className="text-lg bg-white rounded-md my-2 px- cursor-pointer">
                                            {patient.patient_id} - {patient.surname}, {patient.forename}
                                        </p>
                                    </a>
                                ))}
                        </div>
                    </>
                )}

            </form>
        </div>
    );
}
