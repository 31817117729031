import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../context/LoginContext";
import { IReminderAppointment, IReminderMessage, IReminderRecall, MixedReminder } from "../types/Reminder.types";
import { differenceInMinutes, format } from "date-fns";
import { StretchedAction, StretchedLink } from "../shared/StretchedLink";
import { ReactNode, useEffect, useState } from "react";
import noDefault from "../shared/noDefault";
import ReminderEdit, { IEditReminder } from "./ReminderEdit";
import { ReminderCreate } from "./ReminderCreate";
import CheckBox from "../patientHomepage/CheckBox";
import { useNavbarStore } from "../context/NavbarContext";
import { GetPatientNav } from "../patientHomepage/patientNav";

export default function Reminder() {
    const {data, patient, status, type, showDeleted } = useLoaderData() as {data: MixedReminder[], patient: any, status: string[], type: string[], showDeleted: boolean};
    const async_authorised_request = useLoginStore(state => state.async_authorised_request)
    const [filter, setFilter] = useState<{type:string[], status:string[], showDeleted:boolean, patient:any}>({type, status, showDeleted, patient})
    const [remEdit, setRemEdit] = useState<MixedReminder | null>(null);
    const [reminders, setReminders] = useState<MixedReminder[]>(data);
    const [remCreate, setRemCreate] = useState<Partial<MixedReminder> | null>(null);

    const setContent = useNavbarStore(state => state.setContent);

    useEffect(() => {
        if (patient && patient._id){
            setContent(GetPatientNav(patient._id, "Reminders"));
        }
        return () => {
            setContent([]);
        }
    }, [patient])

    useEffect(() => {
        // TODO: Fix calls data twice on mount - but in testing doesn't actually cause any issues or extra renders 
        if (filter.status.length === 0 || filter.type.length === 0){
            setReminders([]);
            return
        }

        const params = new URLSearchParams();

        for (const s of filter.status){
            params.append("status[]", s)
        }

        for (const t of filter.type){
            params.append("type[]", t)
        }

        if (filter.patient && filter.patient._id){
            params.append("patient", filter.patient._id);
        }
        
        async_authorised_request("GET", "reminder?"+params.toString(), undefined).then((data) => {
            setReminders(data.reminders);
        }).catch((error) => {
            console.log(error)
        })
    }, [filter.patient, filter.status, filter.type])

    function UpdateReminder(rem: IEditReminder){
        if (!rem.action){
            return
        }

        if (rem.action === "Complete"){
            async_authorised_request("POST", "reminder/complete/"+rem._id, undefined).then((data) => {
                setReminders(reminders.map(r => r._id === rem._id?{...r, completed_at:data.reminder.completed_at}:r));
                setRemEdit(null);
            }).catch((error) => {
                console.log(error);
            })  
            return
        }

        if (rem.action === "Delete"){
            async_authorised_request("POST", "reminder/delete/"+rem._id, undefined).then((_) => {
                setReminders(reminders.map(r => r._id === rem._id?{...r, is_deleted:true}:r));
                setRemEdit(null);
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    function TableRow(rem:  MixedReminder){
        let kind : ReactNode = "Error";
        let reason = "There was an error with this recall ("+rem.kind+")";
    
        if (rem.kind === "appointment"){
            rem = rem as IReminderAppointment;
            kind = "Appointment"
            reason = rem.appointment.title + " | " + format(rem.appointment.start, "dd/MM/yy HH:mm") + " (" + differenceInMinutes(rem.appointment.end, rem.appointment.start) + ")";
        }
    
        if (rem.kind === "message"){
            rem = rem as IReminderMessage;
            kind = "Message";
            reason = rem.title;
        }

        if (rem.kind === "recall"){
            rem = rem as IReminderRecall;
            kind = "Recall";
            reason = `${format(rem.appointment.start, "dd/MM/yy HH:mm")}`;
        }
    
        return (<tr className={("relative hover:cursor-pointer hover:bg-gray-100"+(rem.deleted_at?" strike-through":""))} key={rem._id}>
            <td> <StretchedAction onclick={noDefault(() => {setRemEdit(rem)})}>{kind}</StretchedAction> </td>
            <td> {reason} </td>
            <td className="relative hover:font-semibold"><StretchedLink to={"/patient/"+rem.patient._id}>{rem.patient.forename} {rem.patient.surname} ({rem.patient.patient_id}) </StretchedLink></td>
            <td>{format(new Date(rem.due_at), "dd/MM/yy HH:mm")}</td>
            <td>{rem.completed_at?format(rem.completed_at, "dd/MM/yy HH:mm"):rem.status}</td>
            </tr>)
    }
    return (
        <div className="flex flex-col items-center py-2">
            <h1 className="text-2xl font-bold m-2">{patient&&patient.forename + " " + patient.surname + " - "} Reminders</h1>


            <form className="text-xl flex flex-row select-none">
               
                <div className="m-1.5">
                    <CheckBox getState={filter.type} setState={newState => setFilter({...filter, type:newState})} editable={true} options={[{value:"appointment", key:"Appointment"}, {value:"message", key:"Message"}, {value:"recall", key:"Recall"}]} />
                </div>

                <div className="m-1.5">
                    <CheckBox getState={filter.status} setState={newState => setFilter({...filter, status:newState})} editable={true} options={[{value:"Pending", key:"Pending"}, {value:"Overdue", key:"Overdue"}, {value:"Completed", key:"Completed"}]} />
                </div>

                <div className="m-1.5 flex flex-col">
                <button onClick={noDefault(() => { if (patient) setRemCreate({patient:{...patient, forced:true}}); else setRemCreate({})}  )} className="m-1 bg-slate-600 text-white rounded-sm">Create</button>   
               <hr className="my-1.5"/>
               <button onClick={noDefault(() => setFilter({...filter, showDeleted: !filter.showDeleted} ) )} className="m-1 bg-stone-500 text-white px-2 py-0 rounded-sm">{filter.showDeleted?"Hide":"Show"} Deleted</button>

                </div>
         
        </form>
        
        <div className="w-4/5 my-2">
            <table className="w-full divide-y divide-gray-200 text-center">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="w-1/7 px-6 py-3 font-semibold uppercase tracking-wider">Type</th>
                        <th className="w-3/7 px-6 py-3 font-semibold uppercase tracking-wider">Reason</th>
                        <th className="w-1/7 px-6 py-3 font-semibold uppercase tracking-wider">Patient</th>
                        <th className="w-1/7 px-6 py-3 font-semibold uppercase tracking-wider">Due</th>
                        <th className="w-1/7 px-6 py-3 font-semibold uppercase tracking-wider">Completed</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {reminders.filter(r => filter.showDeleted || !r.deleted_at).map(TableRow)}
                </tbody>
            </table>
        </div>
        
        {remEdit && 
        <div onClick={noDefault(()=>setRemEdit(null))} className="fixed w-full h-screen overflow-clip flex items-center justify-center">
            <div onClick={noDefault(() => {})} className="bg-stone-300 rounded-lg m-5 p-5">
                <ReminderEdit reminder={remEdit} saveReminder={UpdateReminder} />
            </div>
        </div>}

        {
            remCreate !== null &&
            <div onClick={noDefault(()=>setRemCreate(null))} className="fixed w-full h-screen overflow-clip flex items-center justify-center">
                <div onClick={noDefault(() => {})} className="bg-stone-300 rounded-lg m-5 p-5">
                    <ReminderCreate async_authorised_request={async_authorised_request} reminder={remCreate} createReminder={(newReminder) => {setRemCreate(null), setReminders([...reminders, newReminder])}}/>
                </div>
            </div>
        }

    </div>
    );
}

export function ReminderLoader({params, request}: {params: Params<string>, request:any}){
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    const query = new URLSearchParams();

    // const querySearch = new URLSearchParams(request.url.search);
    // console.log(querySearch.toString())
    const status : string[]= []
    const type= ["recall"]
    if (params.id){
        query.append("patient", params.id);
        if (request.url.search("next") !== -1){
            query.append("status[]", "Pending")
            query.append("status[]", "Overdue")
            status.push("Pending")
            status.push("Overdue")
        }else if (request.url.search("completed") !== -1){
            query.append("status[]", "Completed")
            status.push("Completed")
        }else{
            type.push("message")
            type.push("appointment")
            query.append("status[]", "Overdue")
            status.push("Overdue")
        }
    }else{
        query.append("status[]", "Overdue")
        status.push("Overdue")
        type.push("message")
        type.push("appointment")
    }

    return Promise.all([
        async_authorised_request("GET", "reminder?"+query.toString(), undefined),
        params.id?async_authorised_request("GET", "patient/lean/"+params.id, undefined):Promise.resolve(undefined)
    ]).then(([data, patient]) => {
        return {data: data.reminders, patient:patient?.patient, status, type, showDeleted:false};
    }).catch((error) => {
        return error
    })
}


export function ReminderShortText(rem: MixedReminder){
    switch (rem.kind){
        case "appointment":
            const app = rem as IReminderAppointment;
            return ["Appointment", app.appointment.title + " | " + format(app.appointment.start, "dd/MM/yy HH:mm") + " (" + differenceInMinutes(app.appointment.end, app.appointment.start) + ")"];
        case "message":
            return ["Message",(rem as IReminderMessage).title];
        case "recall":
            const recall = rem as IReminderRecall;
            return ["Recall", `${format(recall.appointment.start, "dd/MM/yy HH:mm")} with ${recall.performer.forename} ${recall.performer.surname}`];
        default:
            return ["Error"];
    }
}

