import { ISocial } from "../types/Patient.types";
import { SocialPlatformEnum } from "../types/Program.types";

export interface ISocialFieldProps {
    setState: (newSocial: ISocial[]) => void;
    getState: ISocial[];
    editable?: boolean;
}

export default function SocialField({setState, getState, editable}: ISocialFieldProps){

    const deleteButton = (index: number) => {
        return (e: { preventDefault: () => void; }) => {
            e.preventDefault();
            delete getState[index];
            setState(getState);
        }
    }

    return (
            <div className="w-64"> <label className="">Social Media</label> {editable && <button className="text-2xl font-bold p-0 -translate-y-1 text-teal-700 scale-125" onClick={e => {e.preventDefault(); setState([...getState, {platform:"X", social:"New Social", _active:false}])}}> + </button>}
            {getState.map((social, index) => {
                return (
                <div className="flex border-b-2 border-0 p-0 relative w-full" key={index}> 
                    <select className={"mx-1 rounded-sm text-black "+(!editable && "font-bold")} value={social.platform} disabled={!editable} onChange={e=>setState(getState.map((s,i) => {return i===index?{...s, platform:e.target.value}:s} ))}> {
                        Object.keys(SocialPlatformEnum).map((platform, index) => {
                            return <option key={index} value={platform}> {SocialPlatformEnum[platform as keyof typeof SocialPlatformEnum]} </option>
                        })} 
                    </select> 
                    <input value={social.social} disabled={!editable} className="px-1 inline w-full flex-grow" onChange={e=>{e.preventDefault(); setState(getState.map((f,i) => {return i===index?{...f, social:e.target.value}:f} ))}}/>
                {editable && <button type="button" className="text-red-600 absolute -right-6 -top-0.5 p-2 float-right -translate-x-full scale-150  font-bold" onClick={deleteButton(index)}> X </button>}
                </div>)
            })}
        </div>);  
}
