import { Navigate, Outlet, useNavigation } from 'react-router-dom'
import './App.css'
import Navbar from './components/shared/Navbar'
import { useLoginStore } from './components/context/LoginContext'
import Spinner from "./components/shared/SpinnerWedges.svg"

export default function App() {
  const users = useLoginStore(state => state.users)
  const state = useNavigation().state;

  return (users && users.length) > 0 ?
    <>
      <Navbar/>
      {state === "loading" ? 
      <div className="flex justify-center items-center h-screen">
        <img className='h-72 w-72' src={Spinner} alt="loading"/>
      </div> 
      :<Outlet/>}
    </>
  :<Navigate to="/login"/>
}
