import { create } from "zustand";
import { api_request, APIMethod } from "../shared/api";

export interface User {
  username: string;
  access: string;
  access_expire: number;
  refresh: string;
  refresh_expire: number;
}

export interface IResponseData extends Record<string, any> {
  status: number;
}

export type async_authorised_request = (method: APIMethod, req: string, data: object | undefined) => Promise<IResponseData>;

export interface Login {
  users: User[];
  active_user: number;
  logout: () => void;
  login: (
    name: string,
    password: string,
  ) => Promise<User>;
  login_google: (token: string) => Promise<User>;
  setUsers: (users: User[]) => void;
  authorised_request: (method: APIMethod, req: string, data: object | undefined, callback: (object: IResponseData ) => void) => void;
  async_authorised_request: (method: APIMethod, req: string, data: object | undefined) => Promise<IResponseData>;
  async_secure_image_request: (id: string, dimensions?: {x:number, y:number}) => Promise<Blob>;
  refresh_token: (refresh: string) => Promise<User>;
}

export const useLoginStore = create<Login>()((set, get) => ({
  users: [],
  active_user: -1,

  refresh_token: (refresh: string) => {
    return new Promise((res, rej) => {
      api_request("POST", "refresh", refresh, undefined, "/api/auth/token/").then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            let user = { username: data.user.username, access:data.access, access_expire: data.access_expire, refresh:data.refresh, refresh_expire: data.refresh_expire };
            set((state) => {
              let new_state:any = {}
              if (state.active_user < 0 || state.active_user >= state.users.length) {
                new_state.users = [...state.users, user]
                new_state.active_user = state.users.length - 1;
              }else{
                new_state.users[state.active_user] = user;
              }
              return new_state;
            });
            res(user);
          });
        } else {
          rej(response);
        }
      }).catch((error) => {
        rej(error);
      });
    });
  },

  authorised_request: (method: APIMethod, req: string, data: object | undefined, callback) => {
    if (get().active_user === -1) {
      return callback({status: 401, error: "Not logged in"});
    } 

    api_request(method, req, get().users[ get().active_user].access, data).then(res => {
      if (res.status === 401) {
        get().logout();
        return callback({status: 401, error: "Not logged in"});
      }

      if (res.status === 502) {
        get().logout();
        return callback({status: 502, error: "API Unavailable"});
      }
      
      if (res.headers.get("Content-Type") !== "application/json; charset=utf-8") {
        return callback({status: 500, error: "Invalid response"});
      }

      res.json().then((data) => {
        data.status = res.status;
        return callback(data);
      });
    }).catch((error) => {
      return callback({status: 500, error: error.message});
    });
  },

  setUsers: (users: User[]) => { // Used by session storage
    set((_) => {
      return { users, active_user: users.length - 1 };
    });
  },

  logout: () => {
    set((state) => {
      if (state.active_user === -1) {
        api_request("POST", "logout", undefined, undefined, "/api/auth/token/");
        return state;
      }else{
        api_request( "POST", "logout", state.users[state.active_user].refresh, undefined, "/api/auth/token/")
        let new_users = [...state.users];
        new_users.splice(state.active_user, 1);
        state.active_user -= 1;
        return {active_user: state.active_user-1, users: new_users};
      };
    });
  },

  login: (username: string, password: string) => {
    return new Promise((res, rej) => {
      api_request( "POST","local/login", undefined, { username, password }, "/api/auth/").then((response) => {
        if (response.status === 200) {
  
          response.json().then((data) => {
            let user = { username: data.user.username, access:data.access, access_expire: data.access_expire, refresh:data.refresh, refresh_expire: data.refresh_expire };
            
            set((state) => {
              return {
                users: [...state.users, user],
                active_user: state.users.length,
              };
            });
  
            res(user);
          });
        } else {
          rej(response.status);
        }
      }).catch((error) => {
        rej(error);
      })
    });
  },

  login_google: (token: string) => {
    return new Promise((res, rej) => {
      api_request( "POST","google/login", undefined, {credential:token}, "/api/auth/").then((response) => {
        if (response.status !== 200) {
          rej(response.status);
          return;
        }
        response.json().then((data) => {
          let user = { username: data.user.username, access:data.access, access_expire: data.access_expire, refresh:data.refresh, refresh_expire: data.refresh_expire };
          set((state) => {
            return {
              users: [...state.users, user],
              active_user: state.users.length,
            };
          });
          res(user);
        });
      }).catch((error) => {
        rej(error);
      });
    });
  },

  async_authorised_request: (method: APIMethod, req: string, data: object | undefined) => new Promise((res, rej) => get().authorised_request(method, req, data, (response) => {
    if ("error" in response) {
      rej(response);
    }
    
    res(response);
  })),

  async_secure_image_request: (id: string, dimensions?: {x:number, y:number}) => new Promise((res, rej) => {
    if (get().active_user === -1) {
      return rej({status: 401, error: "Not logged in"});
    }

    if (id !== "system/homepage" && id.length !== 24) {
      return rej({status: 400, error: "Invalid image ID"});
    }
    
    if (dimensions){
      if (dimensions.x < 10 || dimensions.y < 10){
        return rej({status: 400, error: "Invalid dimensions"});
      }

      id += `?size_x=${dimensions.x}&size_y=${dimensions.y}`;
    }

    api_request( "POST","image/"+id, get().users[ get().active_user].access, undefined).then(response => {
      if (response.status === 401) {
        get().logout();
        return  rej({status: 401, error: "Not logged in"});
      }

      const type = response.headers.get("Content-Type") 
      if (!type || (!type.startsWith("image/") && type !== "application/octet-stream")) {
        return rej({status: 500, error: "Invalid response"});
      }

      response.blob().then(res);
    }).catch(rej);
  }),
}));


