import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../context/LoginContext";
import { IPopulatedPatient } from "../types/populated.types";
import { IImage } from "../types/Image.types";
import SecureImage from "../shared/SecureImage";
import { useNavbarStore } from "../context/NavbarContext";
import { useEffect, useState } from "react";
import { GetPatientNav } from "../patientHomepage/patientNav";
import { format } from "date-fns";
import noDefault from "../shared/noDefault";

function blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof reader.result !== "string") {
                reject("Invalid reader result");
                return;
            }
            resolve(reader.result?.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export default function PatientImages(){
    const {patient, images} = useLoaderData() as {patient: IPopulatedPatient, images: IImage[]};
    const setContent = useNavbarStore(state => state.setContent);
    const {async_authorised_request, async_secure_image_request} = useLoginStore(state=>{return {async_authorised_request: state.async_authorised_request, async_secure_image_request: state.async_secure_image_request}})

    const [renderImages, setRenderImages] = useState<IImage[]>(images);
    const [selected, setSelected] = useState<IImage | null>(null);

    useEffect(() => {

        setContent([
            ...GetPatientNav(patient._id, "Images"), 
            <label className="ml-auto bg-stone-500 rounded-sm font-semibold text-white px-4 py-1 mr-1 cursor-pointer" onDrop={handleDrop}>
                Upload Image
                <input type="file" accept="image/*" onChange={handleFileChange}  className="sr-only" />
            </label>
            ]);

        return () => {
            setContent([]);
        }
    }, [patient._id])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        handleFiles(event.target.files);
    };
    
    const handleFiles = (files: FileList) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            blobToBase64(file).then((result) => {
                async_authorised_request("POST", `patient/images/new`, {patient_id: patient._id, name: file.name, buffer:result}).then((response) => {
                    if (response.status === 201){
                        setRenderImages([response.image, ...renderImages]);
                    }
                })
            }).catch((error) => {
                console.error(error);
            });
        }
    };
    
    const handleDrop = (event: any) => {
        event.preventDefault();
        if (!event.dataTransfer.files) return;
        handleFiles(event.dataTransfer.files);
    };

    return (
        <div className="mx-2 overflow-x-hidden ">
            <h1 className="text-2xl m-2">Images - {patient.surname}, {patient.forename}</h1>

            <div className="grid md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 ">{
            renderImages.map((image) => {
                return (
                    <div id={image._id+"jump"} key={image._id} className="p-2 m-1 bg-stone-300 rounded-md flex flex-col justify-between" onClick={noDefault(()=>setSelected(image))}>
                        <h1 className="text-xl">{image.name}</h1>
                        <div className="flex items-center p-1 h-full">
                            <SecureImage secure={async_secure_image_request} alt={image.name} id={image._id} className="w-full" dimensions={{x:480, y:480}}/>
                        </div>
                        <div className="flex flex-row space-x-1 align-bottom mt-auto">
                            <p>{image.created_by?`${image.created_by.forename} ${image.created_by.surname}`: "SYSTEM"}</p>
                            <p>{format(new Date(image.created_at), "dd/MM/yy HH:mm ")}</p>
                        </div>
                    </div>
                )
            })}
             </div>
            {selected && <div className="fixed top-10 left-0 w-full h-screen overflow-clip flex flex-col p-5 justify-center backdrop-blur-sm" onClick={noDefault(()=>setSelected(null))}>
                <div className="p-2 mx-auto bg-stone-400 rounded-md flex flex-col justify-evenly h-4/5">
                    <h1 className="text-xl">{selected.name}</h1>
                    <div className="h-5/6 mt-auto">
                        <SecureImage secure={async_secure_image_request} alt={selected.name} id={selected._id} className="max-h-full max-w-full object-contain"/>
                    </div>
                    <div className="flex flex-row space-x-1 align-bottom mt-auto">
                        <p>{selected.created_by?.forename} {selected.created_by?.surname}</p>
                        <p>{format(new Date(selected.created_at), "dd/MM/yy HH:mm ")}</p>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export function PatientImagesLoader({params}: {params: Params<string>}){
    const {async_authorised_request} = useLoginStore.getState();
    if (!async_authorised_request){
        return {status: 401, error: "Not logged in"};
    }
    
    const patient_id = params.id;
    if (!patient_id || patient_id.length !== 24){
        return {status: 400, error: "Invalid patient id"};
    }

    return Promise.all([
        async_authorised_request("GET", `patient/lean/${patient_id}`, undefined),
        async_authorised_request("GET", `patient/images/${patient_id}`, undefined)
    ]).then((requests) => {
        return {patient: requests[0].patient, images: requests[1].images};
    }).catch((error) => {
        return {status: 500, error: error.message};
    });
}