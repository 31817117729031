import { useLoginStore } from "../context/LoginContext";
import SecureImage from "../shared/SecureImage";

export default function Index () {
    const async_secure_image_request = useLoginStore((state) => state.async_secure_image_request);
    
    return (
        <div className="flex justify-center items-center h-screen">
            <SecureImage alt="homePage" id="system/homepage" secure={async_secure_image_request}/>
        </div>
    );
}
