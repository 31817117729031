import { Navigate, useRouteError } from 'react-router-dom';

export default function Error () {
    const error:any = useRouteError();
    console.log(error);
    if (error.status === 401){
        return <Navigate to="/login"/>
    }
    return (
        <div>
            <h1>There was an error</h1>
            <h3>Please refresh the page and try again...</h3>
            <p><code>{error.message}</code></p>
        </div>
    );
}
