import { useLoaderData, useNavigate } from "react-router-dom";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useEffect, useMemo, useState, } from "react";
import { IEvent } from "../types/Event.types";
import EventModal, { IModalEvent } from "./EventModal";
import { useProgramContext } from "../context/ProgramContext";
import { enGB } from "date-fns/locale/en-GB";
import { IResponseData, useLoginStore } from "../context/LoginContext";
import { add, addDays, format, getDay, parse, startOfWeek, sub } from "date-fns";
import "./Diary.css"
import noDefault from "../shared/noDefault";
import { IProgramEventReason } from "../types/Program.types";
import { useNavbarStore } from "../context/NavbarContext";

const locales = {
  "en-GB": enGB,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});


export default function Diary() {
  const loader_data = useLoaderData() as any;
  const setContent = useNavbarStore((state) => state.setContent);
  const [getState, setState] = useState(loader_data);
  const [modalEvent, setModalEvent] = useState<IModalEvent | null>(null);
  const [getErrors, setErrors] = useState([] as string[])
  const authorised_request = useLoginStore((state) => state.authorised_request);

  const navigate = useNavigate();

  const {eventReasons, eventBooks} = useProgramContext.getState();
  
  const activeEventBooks = useMemo(() =>eventBooks.filter(b => b.is_active), [ eventBooks ])

  const events = (getState && getState.events)? getState.events.map((e:IEvent)=> {return {...e, start:new Date(e.start), end:new Date(e.end),patient:e.patient, patient_ref:e.patient?.patient_id, resourceId:e.book_id}}):[]

  const updateEventAudit = (event_id: string, button: "arrived" | "seen" | "departed" ) => {
    authorised_request("POST", "event/time/"+event_id, {[button]: true}, (response: IResponseData) => {
      if (response.status === 200) {
        setState({...getState, events: getState.events.map((ev:IEvent) => { if (ev._id === response.event._id) {return {...ev, arrived:response.event.arrived, seen:response.event.seen, departed:response.event.departed}} return ev})});
      } else if (response.status === 400) {
        alert(response.error)
      }
    });
  }

  useEffect(() => {
    setContent([
      <button onClick={noDefault(() => {
        getEvents(add(getState.date, {weeks:-1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm">Previous Week</button>,

      <button onClick={noDefault(() => {
        getEvents(add(getState.date, {days:-1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm">Previous</button>, 

      <button onClick={noDefault(() => {
        getEvents(new Date()).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm">Today</button>,
      
      <button onClick={noDefault(() => {
        getEvents(add(getState.date, {days:1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm">Next</button>

      ,<button onClick={noDefault(() => {
        getEvents(add(getState.date, {weeks:1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm">Next Week</button>,

      <h1 className="mx-auto text-3xl">{format(getState.date, "MMMM")} {format(getState.date, "do")}</h1>,

      <div className="flex">
        <button onClick={noDefault(() => {
          getEvents(add(getState.date, {months:3})).then(setState).catch(console.error);
        })} className="text-xl m-1 order-1 bg-stone-700 p-1 px-2 rounded-sm float-end">3 Month</button>
                  
        <button onClick={noDefault(() => {
          getEvents(add(getState.date, {months:6})).then(setState).catch(console.error);
        })} className="text-xl m-1 order-2 bg-stone-700 p-1 px-2 rounded-sm float-end">6 Month</button>

        <button onClick={noDefault(() => {
        getEvents(add(getState.date, {months:12})).then(setState).catch(console.error);
        })} className="text-xl m-1 order-3 bg-stone-700 p-1 px-2 rounded-sm">12 Month</button>
      </div>
  ]);
  return () => setContent([])
  }, [getState.date])


  const handleNavigate = (d:Date) => {
    getEvents(d).then(setState)
  }

  const handleModelEvent = (e:any) => { // e: IModalEvent|null

    if (e === null){
      setModalEvent(null);
      return
    }
    e.event_reasons.map((er: any) => er._id);

    if (e._id){
      if (e.delete){
        authorised_request( "DELETE", "event/"+e._id, undefined, (response: IResponseData) => {
          if (response.status === 200) {
            setModalEvent(null);
            getEvents(new Date(e.start)).then(setState);
          } else if (response.status === 400) {
            setErrors(response.error.split("; "));
          } else {
            setErrors(["Error deleting event"]);
          }
        });
        return
      }

      const update: any= {}
      const events = getState.events.filter((ev:IEvent) => ev._id === e._id);
      if (events.length === 0) {
        return
      }

      Object.keys(events[0]).forEach((key) => {
        if (events[0][key] !== e[key]) {
          update[key] = e[key];
        }
      });

      if (Object.keys(update).length === 0) {
        setModalEvent(null);
        return
      }
      setErrors([])
      authorised_request( "PUT", "event/"+e._id,update, (response: IResponseData) => {
        if (response.status === 200) {
          setModalEvent(null);
          getEvents(new Date(e.start)).then(setState);
        } else if (response.status === 400) {
          setErrors(response.error.split("; "));
        } else {
          setErrors(["Error updating event"]);
        }
      });
    }else{
      const errors = [];
      if (!e.title.trim()){
        errors.push("Title is required");
      }

      if (!e.event_reasons.length){
        errors.push("At least one Reason is required");
      }

      if (errors.length > 0){
        setErrors(errors);
        return
      }

      authorised_request( "POST","event/create", e, (response: IResponseData) => {
        if (response.status === 200) {
          setModalEvent(null);
          getEvents(new Date(e.start)).then(setState);
        } else if (response.status === 400) {
          setErrors(response.error.split("; "));
        } else {
          setErrors(["Error creating event"]);
        }
      });
    }
  }

  const handleDragEvent = (e: any) => {
    const cutOffDate = add(new Date(), {hours: -1});
    if (e.end < e.start || e.start < cutOffDate || e.event.start < cutOffDate || e.end.getDate() !== e.start.getDate()) {
      return
    }
    
    authorised_request("PUT", "event/"+e.event._id, {start:e.start.getTime(), end:e.end.getTime(), book_id:e.resourceId}, (response: IResponseData) => {
      if (response.status!== 200) {
        console.error("Error updating event");
      }
    });

    setState({...getState, events: getState.events.map((ev:IEvent) => {
      if (ev._id === e.event._id) {
        return {...ev, start:e.start, end:e.end, book_id:e.resourceId}
      }
      return ev;
    })});   
  }
  
  function customEvent({event}:any){
    event.start = new Date(event.start);
    return (
      <div className="h-auto">
        <h3 className="text-2xl italic"> {event.patient.forename} {event.patient.surname} ({event.patient_ref})</h3>
        <h3 className="text-xl font-semibold p-0">{event.title} - {event.departed?"Departed":event.seen?"Seen":event.arrived?"Waiting":"Booked"}</h3>

        <hr className="m-1"/>

        <div>{event.text}</div>

        <div>
          <h3> {event.event_reasons.length===1?"Reason:":"Reasons:"} </h3>
          <ul className="list-disc list-inside"> {event.event_reasons.map( (rsn: IProgramEventReason) => <li key={rsn._id} className="list-item" title={rsn.description}>{rsn.name}</li> )} </ul>
        </div>

        <div hidden={new Date(event.start).getDay() !== new Date().getDay()}>
          <div className="w-full flex-row group-hover:flex hidden absolute bottom-6 text-xl">
            <button disabled={event.arrived} onClick={noDefault(() => updateEventAudit(event._id, "arrived"))} className=" btn w-full mx-0.5 "> Arrived </button>
            <button disabled={event.seen} onClick={noDefault(() => updateEventAudit(event._id, "seen"))} className="btn w-full mx-0.5"> Seen </button>
            <button disabled={event.departed} onClick={noDefault(() => updateEventAudit(event._id, "departed"))} className=" btn w-full mx-0.5"> Departed </button>
          </div>
        </div>
        <h4 className="text-sm italic absolute bottom-0 left-0" >{format(event.start, "HH:mm")} - {format(event.end,"HH:mm")}</h4>

      </div>
    )
  }

  function customToolbar() {
    return (null)
  }

  function customHeader({label}:any) {
    return (
      <div>
        <h2>{label}</h2>
      </div>
    )
  }


  function customEventColour(event: any){
    if (event.event_reasons.length > 0){
      return {style: {backgroundColor: "#"+event.event_reasons[0].colour}, className: "relative group"}
    }
    return {}
  }


  const DndCalendar = withDragAndDrop(Calendar);

  return (
    <div>
     <DndCalendar
     defaultView="day"
      min={new Date(0,0,0,8,0)}
      max={new Date(0,0,0,18,0)}
      views={{day:true}}
      date={getState.date}
      localizer={localizer}
      events={events}
      onNavigate={handleNavigate}
      selectable={true}
      dayLayoutAlgorithm={"no-overlap"}
      enableAutoScroll={true}
      slotPropGetter={(_) => {return {style: {height: "0px"}}}}
      formats={{timeGutterFormat: "HH:mm"}}
      eventPropGetter={customEventColour}
      resizable={getState.date >= add(new Date(), {days: -1})}
      draggableAccessor={(e: any) => !e.seen && !e.departed && e.start >= add(new Date(), {hours: -1})}
      onDoubleClickEvent={(e:any) => {navigate("/patient/"+e.patient_id);}}
      components={{event: customEvent, toolbar:customToolbar, resourceHeader:customHeader }}
      step={5}
      resourceAccessor={(e: any) => e.resourceId}
      resourceTitleAccessor={(e:any) => e.title}
      resources={activeEventBooks.map((b) => {return {resourceId:b._id, title:b.title}})}
      resourceIdAccessor={(b: any) => b.resourceId}
      onEventDrop={handleDragEvent}
      onEventResize={handleDragEvent}
      onSelectEvent={(e: any) =>{setTimeout(() => setModalEvent({...e}), 300)}}
      onSelectSlot={e => e.start >= sub(new Date(), {hours:1}) && setModalEvent({start:e.start.getTime(), reminders_sms:[], end:e.end.getTime(), reminders:[], patient:null, patient_ref:null, book_id:e.resourceId + "", title: "", text: "", event_reasons: []})}
     />
      {modalEvent && <EventModal getErrors={getErrors} event={modalEvent} setEvent={handleModelEvent} eventBooks={activeEventBooks} eventReasons={eventReasons} authorised_request={authorised_request}/>}
    </div>
  );
}


export function getEvents(date: Date) {
  const {async_authorised_request} = useLoginStore.getState();
  if (!async_authorised_request) return Promise.reject({status:402, error:"Not logged in"});

  date.setHours(0, 0, 0, 0);
  let lower = date.getTime();
  let upper = addDays(date, 1).getTime();
  return Promise.all([async_authorised_request( "GET",`event?upper=${upper}&lower=${lower}`, undefined), 
    async_authorised_request( "GET",`session?upper=${upper}&lower=${lower}`, undefined)]).then((responses: IResponseData[]) => {
      return ({events:responses[0].events, date:date, sessions:responses[1].sessions});
    }).catch((_) => {
      return ({events:[], date:date, sessions:[], errors:true});
    });
}

export async function DiaryLoader({request}: any) {
  const params = new URL(request.url).searchParams;
  const query_date = parseInt(params.get('date') || "",10); 
  let date = !isNaN(query_date) ? new Date(query_date) : new Date();
  date = !isNaN(date.getTime()) ? date : new Date();
  let req = await getEvents(date);
  return req;
}
