import { IPatient } from '../types/Patient.types';
import { Link, useNavigate } from 'react-router-dom';
import SocialField from './SocialField';
import DropDown from './DropDown';
import { useProgramContext } from '../context/ProgramContext';
import CheckBox from './CheckBox';
import TelephoneField from './Telephone';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { NavbarLink, useNavbarStore } from '../context/NavbarContext';
import { useEffect, useState } from 'react';
import { IResponseData, useLoginStore } from '../context/LoginContext';
import noDefault from '../shared/noDefault';
import { GetPatientNav } from './patientNav';
import { EmailField } from './EmailField';
import { PrettyPence } from '../patientBalance/PatientBalance';

interface ISearchPatient extends Omit<IPatient, 'dob'>{
  dob: string
}

export interface IPatientViewProps {
    patient: ISearchPatient,
    editable: boolean,
}

function getAge(dob: Date){
  const timestamp = dob.getTime();
  if (isNaN(timestamp) || timestamp > Date.now() || timestamp < -2180217600000) return "N/A";
  return differenceInYears(new Date(), dob) +" ("+ (differenceInMonths(new Date(), dob)-(differenceInYears(new Date(), dob))*12)+ " months)"
}

export default function PatientView (props: IPatientViewProps) {
  let [getPatient, setPatient] = useState<ISearchPatient>(props.patient);
  let [editable, setEditable] = useState<boolean>(props.editable);
  let [errors, setError] = useState<string[]>([]);
  let {setContent, add_patient_history} = useNavbarStore.getState();
  let authorised_request = useLoginStore(state => state.authorised_request);

  const saveAble = getPatient.forename.trim().length > 1 && getPatient.surname.trim().length > 1 && getPatient.address && getPatient.address[0].city.trim().length > 1 && getPatient.address[0]?.postcode.trim().length > 1 && getPatient.dob && getPatient.status

  useEffect(() => {
    if (getPatient && getPatient._id) {
    
      add_patient_history({id:getPatient._id, name: getPatient.forename + " " + getPatient.surname})

      const nav = GetPatientNav(getPatient._id, "Home")
      nav.push(NavbarLink({name: editable?"Cancel":"Edit", action: noDefault(() => setEditable(!editable))}))

      if (editable && saveAble){
        nav.push(NavbarLink({name: "Save", action: noDefault(()=>onSubmit({preventDefault: ()=>{}, stopPropagation: ()=>{}}as any))}))
      }
      setContent(nav);
    }

    return () => {
      setContent([]);
    }
  }, [saveAble,editable, getPatient._id]);

  let programState = useProgramContext(state => state);
  let navigate = useNavigate();

  const parsedDate = new Date(getPatient.dob)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation()
    setEditable(false);
    setError([]);
    getPatient = {...getPatient, dob: format(new Date(getPatient.dob), "yyyy-MM-dd"), email:getPatient.email.filter(e => e!==undefined && e.email.trim().length > 0), telephone:getPatient.telephone.filter(t => t!==undefined && t.number.trim().length > 0), social:getPatient.social.filter(s=> s!=undefined && s.platform.trim().length > 0 && s.social.trim().length > 0)}
    if (getPatient._id) {
      authorised_request( "PATCH", "patient/details/" + getPatient._id,{...getPatient, dob: parsedDate.getTime()}, (response: IResponseData) => {
        if (response.status === 200) {
          setPatient(getPatient);
          return
        } 

        setEditable(true); 
        if (response.errors) {
          setError(response.errors);
          return
        }   

        if (response.error) {
          setError([response.error]);
          return
        }

        setError(["There was an error creating the patient"]);
      });
    } else {
      authorised_request( "POST","patient/details/create", {...getPatient, dob: parsedDate.getTime()}, (response: IResponseData) => {
        if (response.status === 201) {
          setPatient({...response.patient as ISearchPatient, dob: format(new Date(response.patient.dob), "yyyy-MM-dd")});
          navigate("/patient/" + response.patient._id, {replace: true, state: "Patient Created"});
          return
        }

        setEditable(true); 
        if (response.errors) {
          setError(response.errors);
          return
        }   

        if (response.error) {
          setError([response.error]);
          return
        }

        setError(["There was an error creating the patient"]);
      });
    }
  }

  const onDelete = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    authorised_request( "DELETE", "patient/details/" + getPatient._id, undefined, (response: IResponseData) => {
      if (response.status === 200) {
        setPatient({...getPatient, deleted_at:Date.now()});
      } else {
        setError(["There was an error deleting the patient"]);
      }
    });
  }
  
  return (
    <div className='white-card m-4 p-0.5 select-none '>
      <form onSubmit={onSubmit} className='patient-form p-2 grid sm:grid-cols-2  mx-auto lg:max-w-full lg:grid-cols-4'>
      
      
        <label >
          <h1 className='text-2xl'> {(editable && !getPatient._id)  ? "New Patient":"Patient Homepage"}</h1>
          {(getPatient._id && !getPatient.deleted_at && editable) &&<button className="text-red-700 font-bold px-2 inline-flex" onClick={onDelete} disabled={!!getPatient.deleted_at} type='button'> Delete </button>}
            {errors.length > 0 &&
        <div className='pb-4 w-full'>
          {errors.map((e, i) => <p key={i} className='text-red-600 text-lg font-semibold text mx-auto text-center p-0 translate-y-2'>{e}</p>)}
          </div>}
        </label>
       
        <label htmlFor='ref'> ID  <br/>
          <input disabled={!editable} value={getPatient.patient_id} type='text' id='ref' onChange={(e) => setPatient({...getPatient, patient_id: e.target.value})}
          />
        </label>


        <label htmlFor='balance'> Balance <br/>
          <input disabled={true} type='text' id='balance' value={PrettyPence(getPatient.balance||0, true)} className={!!getPatient.balance ? (getPatient.balance < 0?"!bg-red-300": "!bg-emerald-300") : ""}/>
        </label>
        
        <label htmlFor='status'   >Status <br/>
          <DropDown className={errors.includes("Invalid Status") ? "!bg-red-300 !text-black": undefined} editable={editable} options={programState.status.map(s=> {return {key:s.name, value:s._id}})} getState={getPatient.status} setState={(s) => setPatient({...getPatient, status:s})}/>
        </label>

        <label >
          <label htmlFor='forename'>Forename <br/>
          <input className={errors.includes("Invalid Forename") ? "!bg-red-300 !text-black": undefined}  disabled={!editable} type='text' id='forename' value={getPatient.forename} onChange={(e) => setPatient({...getPatient, forename: e.target.value})} />
          </label>
          <br/>
          <label htmlFor='surname'>Surname <br/>
            <input className={errors.includes("Invalid Surname") ? "!bg-red-300 !text-black": undefined}  disabled={!editable} type='text' id='surname' value={getPatient.surname} onChange={(e) => setPatient({...getPatient, surname: e.target.value})} />
          </label>
        </label>

        <label>
          <label htmlFor='sex'> Sex <br/>
            <DropDown className={errors.includes("Invalid Sex") ? "!bg-red-300 !text-black": undefined}  editable={editable} options={[{key:"Male", value:"male"}, {key:"Female", value:"female"}]} getState={getPatient.sex} setState={
              s => setPatient({...getPatient, sex:s, 
                gender: ["Male","Female",""].includes(getPatient.gender)? (s==="male"?"Male":"Female") :getPatient.gender
              })}/>
          </label>
            <br/>
          <label htmlFor='gender'> Gender <br/>
            <input className={errors.includes("Invalid Gender") ? "!bg-red-300 !text-black": undefined}  disabled={!editable} type='text' id='gender' value={getPatient.gender} onChange={(e) => setPatient({...getPatient, gender:e.target.value})} /> 
          </label>
        </label>

        <label>
          <label htmlFor='dob'>Date of Birth <br/>
            <input className={errors.includes("Invalid Date of Birth") ? "!bg-red-300 !text-black": undefined}  disabled={!editable} type='date' id='dob' value={getPatient.dob} onChange={(e) => {setPatient({...getPatient, dob: e.target.value})}} />
          </label>
          <br/>
          <label htmlFor='age'> Age <br/>
            <input disabled={true} type='text' id='age' value={getAge(parsedDate)} />
          </label>
        </label>
        <label>
          <label htmlFor='title'> Title <br/>
            <input className={errors.includes("Invalid Title") ? "!bg-red-300 !text-black": undefined} disabled={!editable} type='text' id='title' value={getPatient.title} onChange={(e) => setPatient({...getPatient, title: e.target.value})} />
          </label>
          <br/>
          <label htmlFor='knownAs'>Known As <br/>
            <input className={errors.includes("Invalid Known As") ? "!bg-red-300 !text-black": undefined} disabled={!editable} type='text' id='knownAs' value={getPatient.knownAs} onChange={(e) => setPatient({...getPatient, knownAs: e.target.value})} />
          </label>
        </label>
        
        <label htmlFor='flag' className={errors.includes("Invalid Flags") ? "!bg-red-300 !text-black": undefined}> Patient Flags <br/>
          <CheckBox editable={editable} getState={getPatient.flags} setState={(newFlags) => setPatient({ ...getPatient, flags: newFlags })} options={programState.flags.map(f=> {return {key:f.name, value:f._id}})}/>
        </label>
        
        <label htmlFor='scheme' className={errors.includes("Invalid Scheme") ? "!bg-red-300 !text-black": undefined}> Scheme <br/>
          <CheckBox editable={editable} getState={getPatient.scheme} setState={(newScheme) => setPatient({...getPatient, scheme:newScheme})} options={programState.schemes.map(s=> {return {key:s.name, value:s._id}})}/>
        </label>

        <label>
          <TelephoneField getState={getPatient.telephone} setState={(newTelephone) => {setPatient({...getPatient, telephone:newTelephone.filter(t => t!==undefined && t.number.trim().length + Number(editable) > 0)})}} editable={editable}/>
        </label>   

        <label>
          <EmailField emails={getPatient.email} setEmails={editable ? (newEmails) => {setPatient({...getPatient, email:newEmails.filter(e => e!==undefined && (e.email.trim().length> 0 || editable))})}:undefined}/>
        </label>

        <label>
          <SocialField getState={getPatient.social} setState={(newSocial) => {setPatient({...getPatient, social:newSocial.filter(s=> s!=undefined && s.platform.trim().length > 0 && s.social.trim().length + Number(editable) > 0)})}} editable={editable} /> 
        </label>
        
        <label> Address <br/>
            <label htmlFor='street1' > Street One: </label> <br/>
              <input  className={errors.includes("Invalid Address") ? "!bg-red-300 !text-black": undefined} disabled={!editable} type='text' id='street1' value={getPatient.address[0].streetOne} onChange={(e) => setPatient({...getPatient, address:[{...getPatient.address[0], streetOne:e.target.value}]})} /><br/>
            <label htmlFor='street2'> Street Two: </label> <br/>
              <input  disabled={!editable} type='text' id='street2' value={getPatient.address[0].streetTwo} onChange={(e) => setPatient({...getPatient, address:[{...getPatient.address[0], streetTwo:e.target.value}]})} /><br/>
            <label htmlFor='city'> City: </label> <br/>
              <input className={errors.includes("Invalid Address") ? "!bg-red-300 !text-black": undefined} disabled={!editable} type='text' id='city' value={getPatient.address[0].city} onChange={(e) => setPatient({...getPatient, address:[{...getPatient.address[0], city:e.target.value}]})} /><br/>
            <label htmlFor='postcode'> Postcode: </label> <br/>
              <input className={errors.includes("Invalid Address") ? "!bg-red-300 !text-black": undefined} disabled={!editable} type='text' id='postcode' value={getPatient.address[0].postcode} onChange={(e) => setPatient({...getPatient, address:[{...getPatient.address[0], postcode:e.target.value}]})} /><br/>
        </label>
        
        {getPatient._id &&
        <label> Attendance <br/>
            <label htmlFor='lastSeen'> Last Appointment: </label> <br/>
              {getPatient.last_appointment?<Link to={`/diary?date=${new Date(getPatient.last_appointment.start).getTime()}`} >
                <input 
                  className='w-full font-bold cursor-pointer'
                  type='text' 
                  readOnly
                  id='lastSeen' 
                  value={format(new Date(getPatient.last_appointment.start), "dd/MM/yy HH:mm - ") + getPatient.last_appointment.title} 
                />
              </Link>:<input className='w-full cursor-default' type='text' id='lastSeen' value='' disabled/>}
              <br/>
            <label htmlFor='nextSeen'> Next Appointment: </label> <br/>
              {getPatient.next_appointment?<Link to={`/diary?date=${new Date(getPatient.next_appointment.start).getTime()}`}>
                <input 
                  className='w-full font-bold cursor-pointer'
                  type='text' 
                  readOnly
                  id='nextSeen' 
                  value={format(new Date(getPatient.next_appointment.start), "dd/MM/yy HH:mm - ") + getPatient.next_appointment.title} 
                />
              </Link>:<input className='w-full cursor-default' type='text' id='nextSeen' value='' disabled/>              
            }

              <br/>
            <label htmlFor='lastRecall'> Last Recall: </label> <br/>
             {getPatient.last_recall?<Link to={`/patient/${getPatient._id}/reminders?recall=last`}>
              <input 
                className='w-full font-bold cursor-pointer'
                type='text' 
                readOnly
                id='lastRecall' 
                value={format(new Date(getPatient.last_recall.due_at), "dd/MM/yy ")+getPatient.last_recall.status} 
              />
              </Link>:<input className='w-full cursor-default' type='text' id='lastRecall' value='' disabled/>            
            }<br/>

            <label htmlFor='nextRecall'> Next Recall: </label> <br/>
           {getPatient.next_recall?<Link to={`/patient/${getPatient._id}/reminders?recall=next`}>
              <input 
                className='w-full font-bold cursor-pointer'
                type='text' 
                readOnly
                id='nextRecall' 
                value={format(new Date(getPatient.next_recall.due_at), "dd/MM/yy ")+getPatient.next_recall.status} 
              />
           </Link>:<input className='w-full  cursor-default' type='text' id='nextRecall' value='' disabled/>}

              <br/>
        </label>}

        {editable && <button className='mx-auto md:col-span-3 sm:col-span-2 lg:col-span-4'  disabled={(!!getPatient.deleted_at || isNaN(parsedDate.getTime()) || getPatient.forename.trim() === "" || getPatient.surname.trim() === "" || !getPatient.sex || getPatient.sex.trim() === "") } type='submit' > {getPatient._id ? "Save": "Create"} </button>}
      </form>

    </div>
  );
}
