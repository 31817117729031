import { useEffect, useState } from "react";
import { IPatient } from "../types/Patient.types";
import { Link, useNavigate } from "react-router-dom";
import { IResponseData, useLoginStore } from "../context/LoginContext";
import { format } from "date-fns";

export interface IPatientSearch {
    id: number | null;
    forename: string | null;
    surname: string | null;
    dob: number | null;
    forename_fuzzy: boolean;
    surname_fuzzy: boolean;
}

export default function PatientSearch () {
    const [search, setSearch] = useState<IPatientSearch>({id: null, forename: null, surname: null, dob: null, forename_fuzzy: false, surname_fuzzy: false});
    const [results, setResults] = useState<IPatient[]>([]);

    const navigate = useNavigate();
    const authorised_request = useLoginStore(state => state.authorised_request);

    const onChange = (submit = false) => {
        let query = new URLSearchParams()
        if (search.id !== null && search.id > 0) {
            query.append("patient_id", search.id.toString());
        }

        const forename = search.forename?.trim();
        const surname = search.surname?.trim();
        if (forename && forename.length >= 2) {
            if (search.forename_fuzzy) {
                query.append("forename_fuzzy", "1");
            }
            query.append("forename", forename);
        }
        if (surname && surname.length >= 2) {
            if (search.surname_fuzzy) {
                query.append("surname_fuzzy", "1");
            }
            query.append("surname", surname);
        }
        if (search.dob) {
            query.append("dob", search.dob.toString());
        }

        if (query.size !== 0){
            authorised_request("GET", "patient/details/search?" + query.toString(), undefined, (response: IResponseData) => {
                if (response.status === 200) {
                    setResults(response.patients as IPatient[]);
                    if (submit && response.patients.length === 1) {
                        navigate("/patient/"+response.patients[0]._id);
                    }
                } else {
                    console.error("Error fetching patient");
                }
            })
        }
    }

    useEffect(onChange, [search])


    return (
        <div>
            <h1 className="text-3xl m-2 font-semibold text-stone-900">Patient Search</h1>

            <form className="search-form grid grid-cols-4 text-right m-3 gap-2" onSubmit={e => {e.preventDefault(); onChange(true)}}>
                <label htmlFor="id">ID <br/>
                <input type="number" id="id" name="id" value={search.id?search.id:""} onChange={
                    (e) => {
                        if (e.target.value === "") {
                            setSearch({...search, id: null});
                            return;
                        }
                        let number = parseInt(e.target.value, 10)
                        if (!isNaN(number)) {
                            setSearch({...search, id: number});
                        }                    }}></input>
                </label>

                <label htmlFor="forename">Forename <br/>
                <div className="flex center">                
                    <label htmlFor="ffuzzy" className="m-1">Similar? <input id="ffuzzy" className="scale-125 hover:cursor-pointer" type="checkbox" value={search.forename_fuzzy? "true" : "false"} onChange={_ => {setSearch({...search, forename_fuzzy: !search.forename_fuzzy})}}></input></label>
                    <input className="ml-auto" type="text" id="forename" name="forename" value={search.forename?search.forename:""} onChange={(e) => {setSearch({...search, forename: e.target.value});}}></input>
                </div>
                </label>
                <label htmlFor="surname">Surname  <br/>
                <div className="flex center">
                    <label htmlFor="sfuzzy" className="m-1">Similar? <input id="sfuzzy" className="scale-125 hover:cursor-pointer" type="checkbox" value={search.surname_fuzzy? "true" : "false"} onChange={_ => {setSearch({...search, surname_fuzzy: !search.surname_fuzzy})}}></input></label>
                    <input className="ml-auto" type="text" id="surname" name="surname" value={search.surname?search.surname:""} onChange={(e) => {setSearch({...search, surname: e.target.value});}}></input>
                </div>
                </label>
                <label htmlFor="dob">DOB <br/>
                <input type="date" id="dob" name="dob" value={search.dob?new Date(search.dob).toISOString().split("T")[0]:""} onChange={(e) => {setSearch({...search, dob: e.target.valueAsNumber}); }}></input>
                </label>
                <div className="flex flex-row col-span-full">
                    <button type="submit" className="bg-stone-700 hover:bg-stone-800 m-1 mx-3 px-1 p-0 text-white font-semibold text-xl rounded-md w-full">Search</button> 
                    <Link className="bg-stone-700 hover:bg-stone-800 m-1 mx-3 px-1 p-0 text-white font-semibold text-xl w-full rounded-md" to="/patient/new"><button className="bg-stone-700 hover:bg-stone-800 w-full inline m-1 p-1 rounded-md text-white font-semibold text-xl">New</button></Link>
                </div>
            </form>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                {results.map((patient) => {
                    return (
                        <Link className="w-full" key={patient.patient_id} to={"/patient/"+patient._id}> 
                            <div className="group rounded-md shadow-md bg-stone-300 font-semibold text-stone-900 hover:bg-stone-200 hover:text-black hover:cursor-pointer m-4 p-3 grid grid-cols-2">
                            <label className="group-hover:cursor-pointer w-min">ID:</label>
                            <label className="group-hover:cursor-pointer">{patient.patient_id}</label>
                            <label className="group-hover:cursor-pointer w-min">Forename:</label>
                            <label className="group-hover:cursor-pointer">{patient.forename}</label>
                            <label className="group-hover:cursor-pointer w-min">Surname:</label>
                            <label className="group-hover:cursor-pointer">{patient.surname}</label>
                            <label className="group-hover:cursor-pointer w-min">DOB:</label>
                            <label className="group-hover:cursor-pointer">{patient.dob&&format(new Date(patient.dob), "dd/MM/yy")}</label>
                            <label className="group-hover:cursor-pointer w-min">Address:</label>
                            <label className="group-hover:cursor-pointer">{patient.address[0]?.streetOne}</label>
                            <label className="group-hover:cursor-pointer w-min"></label>
                            <label className="group-hover:cursor-pointer">{patient.address[0]?.postcode}</label>
                            </div>
                      </Link>
                    );
                })}
            </div>
        
        </div>
    );
}
