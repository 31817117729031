import noDefault from "../shared/noDefault";
import { IEmail } from "../types/Patient.types";

export interface IEmailFieldProps {
    emails: IEmail[];
    setEmails?: (emails: IEmail[]) => void;
}

const emailReg = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");

export function EmailField ({emails, setEmails}: IEmailFieldProps) {
  return (
    <div className="min-w-64 space-y-0.5 p-0.5">
        <label className="">Email</label> {setEmails && <button className="text-2xl font-bold p-0 -translate-y-1 text-teal-700 scale-125" onClick={noDefault(()=>{setEmails([...emails,{email:"", active:false}])})}> + </button>} 
        {emails && emails.map((e, index) => 
            <div key={index} className="flex relative">
                <input type="checkbox" checked={e.active} readOnly={!setEmails} alt="Active?" onChange={(_) => {
                    setEmails && setEmails(emails.map((e, i) => {
                        if (i === index) return {...e, active: !e.active};
                        return e;
                    }))
                }}/>
                <input className={!(e.email.trim().length == 0 || emailReg.test(e.email))?"!bg-red-300 w-full":"w-full"} type="email" value={e.email} readOnly={!setEmails} onChange={(ev) => {
                    setEmails && setEmails(emails.map((e, i) => {
                        if (i === index) return {...e, email: ev.target.value};
                        return e;
                    }))
                }}/>
                {setEmails && <button type="button" className="text-red-700 absolute -right-6 -top-0.5 p-2 float-right -translate-x-full scale-150  font-bold" onClick={() => setEmails(emails.filter((_,i) => i !== index))}> X </button>}
            </div>
        )}
    </div>
  );
}
