import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../context/LoginContext";
import { useEffect, useState } from "react";
import { NavbarLink, useNavbarStore } from "../context/NavbarContext";
import { GetPatientNav } from "../patientHomepage/patientNav";
import { IExamination } from "../types/examination.types";
import Chart from "../patientChart/Chart";
import noDefault from "../shared/noDefault";
import ChartRender from "../patientChart/ChartRender";
import { format } from "date-fns";
import { PrescriptionTable } from "./PrescriptionTable";

const DEFAULT_FORM: IExamination =  {
    visit_reason: { notes: "" },
    history: { details: "", driver: false, occupation: "" },
    current_spectacles: { charting: [{ distance: "Distance", left_add: "", right_add: "", left_axis: "", right_axis: "", left_cylinder: "", right_cylinder: "", left_sphere: "", right_sphere: "", left_base: "", right_base: "", left_prism: "", right_prism: "", left_va: "", right_va: "", }, { distance: "Near", left_add: "", right_add: "", left_axis: "", right_axis: "", left_cylinder: "", right_cylinder: "", left_sphere: "", right_sphere: "", left_base: "", right_base: "", left_prism: "", right_prism: "", left_va: "", right_va: "", }], notes: "" },
    retinoscopy: { left_sphere: "", left_cylinder: "", left_axis: "", left_va: "", right_sphere: "", right_cylinder: "", right_axis: "", right_va: "", notes: "" },
    subjective_refraction: { left_sphere: "", left_cylinder: "", left_axis: "", left_va: "", right_sphere: "", right_cylinder: "", right_axis: "", right_va: "", notes: "" },
    near_addition: { left_add: "", right_add: "", left_nva: "", right_nva: "", range: "", notes: "" },
    muscle_balance: { distance: "", near: "" },
    fixation_disparity: { distance: "", near: "" },
    visual_fields: { notes: "" },
    ocular_examination: { lids_lashes: "", conjunctiva: "", cornea: "", anterior_chamber: "", lens: "", vitreous: "", disc: "", vessels: "", periphery: "", macula: "", notes: "", charting: [] },
    additional_investigations: { notes: "" },
    patient_management: { notes: "" },
    final_prescription: {
        charting: [{
            distance: "Distance",
            left_add: "",
            right_add: "",
            left_axis: "",
            right_axis: "",
            left_cylinder: "",
            right_cylinder: "",
            left_sphere: "",
            right_sphere: "",
            left_base: "",
            right_base: "",
            left_prism: "",
            right_prism: "",
            left_va: "",
            right_va: "",
        }, {
            distance: "Near",
            left_add: "",
            right_add: "",
            left_axis: "",
            right_axis: "",
            left_cylinder: "",
            right_cylinder: "",
            left_sphere: "",
            right_sphere: "",
            left_base: "",
            right_base: "",
            left_prism: "",
            right_prism: "",
            left_va: "",
            right_va: "",
        }], notes: ""
    },


    patient: "", created_at: 0, created_by: null,
    _id: "",
    tonometry: null,
    drugs: null,
    pupils: null,
    ocular_motility: null,
    unaided_visual_acuity: null,
    refraction: null,
    accommodation: null,
    deleted_by: null,
    deleted_at: null
} 

export default function Examination() {
    const { patient, examinations, } = useLoaderData() as { patient: any; examinations: any;};
    const setContent = useNavbarStore((state) => state.setContent);
    const async_authorised_request = useLoginStore((state) => state.async_authorised_request);

    const [getExaminations, setExaminations] = useState<IExamination[]>(examinations || []);
    const [newExamination, setNewExamination] = useState<Partial<IExamination> | undefined>(undefined);

    const SaveForm = ()=> {
        if (newExamination) {
            const exam = newExamination as Partial<IExamination>;
            setNewExamination(undefined);
            exam.patient = patient._id;

            Object.keys(exam).forEach((key) => {
                const keyo = key as any as keyof IExamination;
                if (exam[keyo] == DEFAULT_FORM[keyo]) {
                    exam[keyo] = undefined;
                }   
            })

            if (!exam.accommodation && !exam.additional_investigations && !exam.drugs && !exam.final_prescription && !exam.history && !exam.near_addition && !exam.ocular_examination && !exam.ocular_motility && !exam.patient_management && !exam.pupils && !exam.refraction && !exam.retinoscopy && !exam.subjective_refraction && !exam.tonometry && !exam.unaided_visual_acuity && !exam.visit_reason) {
                setNewExamination(undefined);
                return;
            }

            async_authorised_request("POST", "examination/create", exam).then((res) => {
                if (res.status === 200) {
                    setExaminations([res.examination as IExamination, ...getExaminations]);
                } else {
                    setNewExamination(exam);
                }
            }).catch((_) => {
                setNewExamination(exam);
            });
        }
    }

    useEffect(() => {
        if (patient) {
            const submenu = GetPatientNav(patient._id, "Examination");
            if (newExamination) {
                submenu.push(<NavbarLink name="Save Examination" action={SaveForm}  className="bg-stone-400 text-black px-1 py-0.5 mx-1 font-semibold" />);
            }else{
                submenu.push(<NavbarLink name="New Examination" action={() => setNewExamination({...DEFAULT_FORM})}  className="bg-stone-400 text-black px-1 py-0.5 mx-1 font-semibold" />);
            }
            setContent(submenu);
        }
        return () => {
            setContent([]);
        };
    }, [patient, newExamination]);
    return (
        <div className="p-2">
            <h1 className="text-2xl">
                Examination - {patient.surname}, {patient.forename}
            </h1>
            <div className="m-auto bg-stone-100 rounded-md p-1 border-b">
                <div>
                    {!newExamination ? (
                        <button
                            className="px-3 py-1 bg-stone-400 hover:bg-stone-500 m-1 rounded-sm text-xl font-semibold"
                            onClick={(_) =>
                                setNewExamination({...DEFAULT_FORM})
                            }
                        >
                            New Examination
                        </button>
                    ) : (
                        <div>
                            {newExamination.visit_reason && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Reason for Visit</h1>
                                {/* <button 
                                    onClick={_ => setNewExamination({...newExamination, visit_reason: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button> */}
                                <textarea
                                    value={newExamination.visit_reason.notes}
                                    onChange={e => setNewExamination({ ...newExamination, visit_reason: { notes: e.target.value } })}
                                    className="w-full mx-auto h-32 p-1.5"
                                    placeholder="Reason for Visit"
                                ></textarea>
                            </div>}

                            {newExamination.history && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">History</h1>
                                {/* <button 
                                    onClick={_ => setNewExamination({...newExamination, history: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button> */}
                                <textarea className="w-full mx-auto h-32 p-1.5" placeholder="History" 
                                    onChange={e => setNewExamination({ ...newExamination, history: { ...newExamination.history as any, details: e.target.value } })}
                                    value={newExamination.history.details}
                                ></textarea>
                                <div className="flex flex-row justify-evenly w-1/2 text-lg p-2 mx-auto scale-150">
                                    <label className="block text-sm font-semibold">Driver:</label>
                                    <input title="Drives?" type="checkbox" onChange={e => setNewExamination({ ...newExamination, history: { ...newExamination.history as any, driver: e.target.checked } })} checked={newExamination.history.driver}/>
                                    <label className="block text-sm font-semibold">Occupation:</label>
                                    <input className="px-1 rounded-sm" type="text" placeholder="Occupation" value={newExamination.history.occupation} onChange={e => setNewExamination({ ...newExamination, history: { ...newExamination.history as any, occupation: e.target.value } })}/>
                                </div>
                            </div>}

                           { newExamination.current_spectacles && <div className="w-full border-t border-x relative border-stone-800">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Current Prescription</h1>
                                <button 
                                    onClick={_ => setNewExamination({...newExamination, current_spectacles: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <PrescriptionTable prescriptions={newExamination.current_spectacles.charting} setPrescriptions={charting => setNewExamination({ ...newExamination, current_spectacles: { ...newExamination.current_spectacles as any, charting } })} />
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details"
                                    value={newExamination.current_spectacles.notes}
                                    onChange={e => setNewExamination({ ...newExamination, current_spectacles: { ...newExamination.current_spectacles as any, notes: e.target.value } })}
                                ></textarea>
                            </div>}
                           { newExamination.retinoscopy && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Retinoscopy</h1>
                                <button 
                                    onClick={_ => setNewExamination({...newExamination, retinoscopy: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <table className="table-fixed divide-y w-full divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <thead className="w-full divide-y shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <tr className="">
                                            <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider">
                                                Eye
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Sphere
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Cylinder
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Axis
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                VA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-x p-1 text-right  w-1/5">
                                                
                                                <span className="flex flex-col px-2 font-semibold items-center">
                                                    <p className="p-0.5"> Right </p>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <p className="p-0.5">Left</p>
                                                </span>
                                            </td>
                                            <td className="border-x p-1 text-right w-1/5">
                                                <span className="flex flex-col px-2 font-semibold items-center">
                                                    <input 
                                                        type="number" 
                                                        placeholder="Right Sphere"
                                                        value={newExamination.retinoscopy.right_sphere} 
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, right_sphere: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number" 
                                                        placeholder="Left Sphere"
                                                        value={newExamination.retinoscopy.left_sphere} 
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, left_sphere: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                            <td className="border-x p-1  w-1/5">
                                                
                                                <span className="flex flex-col">
                                                    <input
                                                        type="number"
                                                        placeholder="Right Cylinder"
                                                        value={newExamination.retinoscopy.right_cylinder}
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, right_cylinder: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number"
                                                        placeholder="Left Cylinder"
                                                        value={newExamination.retinoscopy.left_cylinder}
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, left_cylinder: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                            <td className="border-x p-1  w-1/5">
                                                
                                                <span className="flex flex-col">
                                                    <input 
                                                        type="number"
                                                        placeholder="Right Axis"
                                                        value={newExamination.retinoscopy.right_axis}
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, right_axis: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number"
                                                        placeholder="Left Axis"
                                                        value={newExamination.retinoscopy.left_axis}
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, left_axis: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                            <td className="border-x p-1 w-1/5">
                                                
                                                <span className="flex flex-col">
                                                    <input 
                                                        type="number"
                                                        placeholder="Right VA"
                                                        value={newExamination.retinoscopy.right_va}
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, right_va: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number"
                                                        placeholder="Left VA"
                                                        value={newExamination.retinoscopy.left_va}
                                                        onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, left_va: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details" value={newExamination.retinoscopy.notes} onChange={e => setNewExamination({ ...newExamination, retinoscopy: { ...newExamination.retinoscopy as any, notes: e.target.value } })}></textarea>
                            </div>}
                            {newExamination.subjective_refraction && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">
                                    Subjective Refraction
                                </h1>
                                <button 
                                    onClick={_ => setNewExamination({...newExamination, subjective_refraction: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <table className="table-fixed divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <thead className="w-full divide-y shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <tr className="">
                                            <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider">
                                                Eye
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Sphere
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Cylinder
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Axis
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                VA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-x p-1 text-right">
                                                
                                                <span className="flex flex-col px-2 font-semibold items-center">
                                                    <p className="p-0.5"> Right </p>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <p className="p-0.5"> Left </p>
                                                </span>
                                            </td>
                                            <td className="border-x p-1 text-right">
                                                
                                                <span className="flex flex-col px-2 font-semibold items-center">
                                                    <input 
                                                        type="number" 
                                                        placeholder="Right Sphere"
                                                        value={newExamination.subjective_refraction.right_sphere} 
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, right_sphere: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number" 
                                                        placeholder="Left Sphere"
                                                        value={newExamination.subjective_refraction.left_sphere} 
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, left_sphere: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                            <td className="border-x p-1">
                                                
                                                <span className="flex flex-col">
                                                    <input
                                                        type="number"
                                                        placeholder="Right Cylinder"
                                                        value={newExamination.subjective_refraction.right_cylinder}
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, right_cylinder: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number"
                                                        placeholder="Left Cylinder"
                                                        value={newExamination.subjective_refraction.left_cylinder}
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, left_cylinder: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                            <td className="border-x p-1">
                                                
                                                <span className="flex flex-col"> 
                                                    <input 
                                                        type="number"
                                                        placeholder="Right Axis"
                                                        value={newExamination.subjective_refraction.right_axis}
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, right_axis: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number"
                                                        placeholder="Left Axis"
                                                        value={newExamination.subjective_refraction.left_axis}
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, left_axis: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                            <td className="border-x p-1">
                                                
                                                <span className="flex flex-col">
                                                    <input 
                                                        type="number"
                                                        placeholder="Right VA"
                                                        value={newExamination.subjective_refraction.right_va}
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, right_va: e.target.value } })}
                                                    ></input>
                                                    <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                    <input 
                                                        type="number"
                                                        placeholder="Left VA"
                                                        value={newExamination.subjective_refraction.left_va}
                                                        onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, left_va: e.target.value } })}
                                                    ></input>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details" value={newExamination.subjective_refraction.notes} onChange={e => setNewExamination({ ...newExamination, subjective_refraction: { ...newExamination.subjective_refraction as any, notes: e.target.value } })}></textarea>
                            </div>}
                            {newExamination.near_addition && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Near Addition</h1>
                                <button 
                                    onClick={_ => setNewExamination({...newExamination, near_addition: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <div className="flex flex-row">
                                    <table className="table-fixed divide-y w-1/2 divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <thead className="w-full divide-y shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <tr className="">
                                                <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider"></th>
                                                <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                    Right
                                                </th>
                                                <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                    Left
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border-x p-1 font-bold"> Add </td>
                                                <td className="border-x p-1"> 
                                                    <input
                                                    type="number"
                                                    placeholder="Right Add"
                                                    value={newExamination.near_addition.right_add}
                                                    onChange={e => setNewExamination({ ...newExamination, near_addition: { ...newExamination.near_addition as any, right_add: e.target.value } })}
                                                    ></input>
                                                </td>
                                                <td className="border-x p-1">
                                                    <input
                                                        type="number"
                                                        placeholder="Left Add"
                                                        value={newExamination.near_addition.left_add}
                                                        onChange={e => setNewExamination({ ...newExamination, near_addition: { ...newExamination.near_addition as any, left_add: e.target.value } })}
                                                    ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-x p-1 font-bold"> NVA </td>
                                                <td className="border-x p-1"> 
                                                    <input
                                                        type="text"
                                                        placeholder="Right NVA"
                                                        value={newExamination.near_addition.right_nva}
                                                        onChange={e => setNewExamination({ ...newExamination, near_addition: { ...newExamination.near_addition as any, right_nva: e.target.value } })}
                                                    ></input>                                                    
                                                </td>
                                                <td className="border-x p-1">
                                                    <input
                                                        type="text"
                                                        placeholder="Left NVA"
                                                        value={newExamination.near_addition.left_nva}
                                                        onChange={e => setNewExamination({ ...newExamination, near_addition: { ...newExamination.near_addition as any, left_nva: e.target.value } })}
                                                    ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="border-x p-1 font-bold"> Range </td>
                                                <td colSpan={2} className="w-full p-1 text-center">
                                                    <input 
                                                    className="w-4/5 flex ml-3"
                                                        type="text"
                                                        value={newExamination.near_addition.range}
                                                        onChange={e => setNewExamination({ ...newExamination, near_addition: { ...newExamination.near_addition as any, range: e.target.value } })}
                                                    ></input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <textarea className="w-1/2 mx-auto p-1.5" placeholder="Other Details" value={newExamination.near_addition.notes} onChange={e => setNewExamination({ ...newExamination, near_addition: { ...newExamination.near_addition as any, notes: e.target.value } })}></textarea>
                                </div>
                            </div>}
                            {newExamination.muscle_balance && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Muscle Balance</h1>  
                                <button
                                    onClick={_ => setNewExamination({...newExamination, muscle_balance: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Distance" value={newExamination.muscle_balance.distance} onChange={e => setNewExamination({ ...newExamination, muscle_balance: { ...newExamination.muscle_balance as any, distance: e.target.value } })}></textarea>
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Near" value={newExamination.muscle_balance.near} onChange={e => setNewExamination({ ...newExamination, muscle_balance: { ...newExamination.muscle_balance as any, near: e.target.value } })}></textarea>
                            </div>}
                            {newExamination.fixation_disparity && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Fixation Disparity</h1>
                                <button 
                                    onClick={_ => setNewExamination({...newExamination, fixation_disparity: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Distance" value={newExamination.fixation_disparity.distance} onChange={e => setNewExamination({ ...newExamination, fixation_disparity: { ...newExamination.fixation_disparity as any, distance: e.target.value } })}></textarea>
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Near" value={newExamination.fixation_disparity.near} onChange={e => setNewExamination({ ...newExamination, fixation_disparity: { ...newExamination.fixation_disparity as any, near: e.target.value } })}></textarea>
                            </div>}
                            {newExamination.visual_fields && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Visual Fields</h1>
                                <button
                                    onClick={_ => setNewExamination({...newExamination, visual_fields: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Visual Fields" value={newExamination.visual_fields.notes} onChange={e => setNewExamination({ ...newExamination, visual_fields: { ...newExamination.visual_fields as any, notes: e.target.value } })}></textarea>
                            </div>}
                            {newExamination.ocular_examination && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Ocular Examination</h1>
                                <button
                                    onClick={_ => setNewExamination({...newExamination, ocular_examination: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <Chart getCharting={newExamination.ocular_examination?.charting || []} setCharting={newCharting => setNewExamination({ ...newExamination, ocular_examination: { ...newExamination.ocular_examination as any, charting: newCharting } })} />
                                <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Other Details" value={newExamination.ocular_examination?.notes} onChange={e => setNewExamination({ ...newExamination, ocular_examination: { ...newExamination.ocular_examination as any, notes: e.target.value } })}></textarea>
                            </div>}
                            {newExamination.additional_investigations && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Additional Investigations</h1>
                                <button
                                    onClick={_ => setNewExamination({...newExamination, additional_investigations: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Additional Investigations" value={newExamination.additional_investigations.notes} onChange={e => setNewExamination({ ...newExamination, additional_investigations: { ...newExamination.additional_investigations as any, notes: e.target.value } })}></textarea>
                            </div>}
                            {newExamination.patient_management && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Patient Management</h1>
                                <button 
                                    onClick={_ => setNewExamination({...newExamination, patient_management: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Patient Management" value={newExamination.patient_management.notes} onChange={e => setNewExamination({ ...newExamination, patient_management: { ...newExamination.patient_management as any, notes: e.target.value } })}></textarea>
                            </div>}
                            {
                                newExamination.final_prescription && <div className="w-full border-t border-x border-stone-800 relative">
                                <h1 className="bg-stone-300 text-center font-semibold text-lg">Final Prescription</h1>
                                <button 
                                    onClick={_ => setNewExamination({...newExamination, final_prescription: undefined })}
                                    className="absolute right-1 top-0 text-lg font-bold"
                                    >
                                    X
                                </button>
                                <PrescriptionTable prescriptions={newExamination.final_prescription.charting} setPrescriptions={newPrescriptions => setNewExamination({ ...newExamination, final_prescription: { ...newExamination.final_prescription as any, charting: newPrescriptions } })} />
                                <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details" value={newExamination.final_prescription.notes} onChange={e => setNewExamination({ ...newExamination, final_prescription: { ...newExamination.final_prescription as any, notes: e.target.value } })}></textarea>
                                </div>
                            }
                            <div className="border-t border-stone-800 flex"><button className="w-1/2 mx-auto bg-stone-600 text-white m-1 p-1 text-xl rounded-md" onClick={noDefault(SaveForm)}>Save Examination</button> </div>
                         </div>

                    )}
                </div>
            </div>

            <div>
                {getExaminations.map((cExam) => (
                    <div key={cExam._id} className="p-1">
                        <h1 className="text-xl p-1 border-t border-x border-b-0 border-stone-800 bg-stone-400 font-semibold">{format(new Date(cExam.created_at as any), "dd/MM/yy HH:mm")} - {cExam.created_by? `${cExam.created_by?.forename} ${cExam.created_by?.surname}`: "SYSTEM"}</h1>
                        {cExam.visit_reason && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Reason for Visit</h1>
                            <textarea
                                value={cExam.visit_reason.notes}
                                className="w-full mx-auto h-32 p-1.5"
                                placeholder="Reason for Visit"
                                readOnly
                            ></textarea>
                        </div>}

                        {cExam.history && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">History</h1>
                            <textarea className="w-full mx-auto h-32 p-1.5" placeholder="History" 
                                readOnly
                                value={cExam.history.details}
                            ></textarea>
                            <div className="flex flex-row justify-evenly w-1/2 text-lg p-2 mx-auto scale-150">
                                <label className="block text-sm font-semibold">Driver:</label>
                                <input title="Drives?" type="checkbox" readOnly checked={cExam.history.driver}/>
                                <label className="block text-sm font-semibold">Occupation:</label>
                                <input className="px-1 rounded-sm" type="text" placeholder="Occupation" value={cExam.history.occupation} readOnly/>
                            </div>
                        </div>}

                        { cExam.current_spectacles && <div className="w-full border-t border-x relative border-stone-800">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Current Prescription</h1>
                            <PrescriptionTable prescriptions={cExam.current_spectacles.charting} />
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details"
                                value={cExam.current_spectacles.notes}
                                readOnly
                            ></textarea>
                        </div>}
                        { cExam.retinoscopy && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Retinoscopy</h1>
                            <table className="table-fixed divide-y w-full divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <thead className="w-full divide-y shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <tr className="">
                                        <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider">
                                            Eye
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            Sphere
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            Cylinder
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            Axis
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            VA
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border-x p-1 text-right  w-1/5">
                                            <span className="flex flex-col px-2 font-semibold items-center">
                                                <p className="p-0.5"> Right </p>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <p className="p-0.5">Left</p>
                                            </span>
                                        </td>
                                        <td className="border-x p-1 text-right w-1/5">
                                            <span className="flex flex-col px-2 font-semibold items-center">
                                                <input 
                                                    type="number" 
                                                    placeholder="Right Sphere"
                                                    value={cExam.retinoscopy.right_sphere} 
                                                    readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number" 
                                                    placeholder="Left Sphere"
                                                    value={cExam.retinoscopy.left_sphere} 
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                        <td className="border-x p-1  w-1/5">
                                            
                                            <span className="flex flex-col">
                                                <input
                                                    type="number"
                                                    placeholder="Right Cylinder"
                                                    value={cExam.retinoscopy.right_cylinder}
                                                    readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number"
                                                    placeholder="Left Cylinder"
                                                    value={cExam.retinoscopy.left_cylinder}
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                        <td className="border-x p-1  w-1/5">
                                            
                                            <span className="flex flex-col">
                                                <input 
                                                    type="number"
                                                    placeholder="Right Axis"
                                                    value={cExam.retinoscopy.right_axis}
                                                   readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number"
                                                    placeholder="Left Axis"
                                                    value={cExam.retinoscopy.left_axis}
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                        <td className="border-x p-1 w-1/5">
                                            
                                            <span className="flex flex-col">
                                                <input 
                                                    type="number"
                                                    placeholder="Right VA"
                                                    value={cExam.retinoscopy.right_va}
                                                   readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number"
                                                    placeholder="Left VA"
                                                    value={cExam.retinoscopy.left_va}
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details" value={cExam.retinoscopy.notes} readOnly></textarea>
                        </div>}
                        {cExam.subjective_refraction && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">
                                Subjective Refraction
                            </h1>
                            <table className="table-fixed divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <thead className="w-full divide-y shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <tr className="">
                                        <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider">
                                            Eye
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            Sphere
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            Cylinder
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            Axis
                                        </th>
                                        <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                            VA
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border-x p-1 text-right">
                                            
                                            <span className="flex flex-col px-2 font-semibold items-center">
                                                <p className="p-0.5"> Right </p>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <p className="p-0.5"> Left </p>
                                            </span>
                                        </td>
                                        <td className="border-x p-1 text-right">
                                            
                                            <span className="flex flex-col px-2 font-semibold items-center">
                                                <input 
                                                    type="number" 
                                                    placeholder="Right Sphere"
                                                    value={cExam.subjective_refraction.right_sphere} 
                                                    readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number" 
                                                    placeholder="Left Sphere"
                                                    value={cExam.subjective_refraction.left_sphere} 
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                        <td className="border-x p-1">
                                            
                                            <span className="flex flex-col">
                                                <input
                                                    type="number"
                                                    placeholder="Right Cylinder"
                                                    value={cExam.subjective_refraction.right_cylinder}
                                                    readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number"
                                                    placeholder="Left Cylinder"
                                                    value={cExam.subjective_refraction.left_cylinder}
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                        <td className="border-x p-1">
                                            
                                            <span className="flex flex-col"> 
                                                <input 
                                                    type="number"
                                                    placeholder="Right Axis"
                                                    value={cExam.subjective_refraction.right_axis}
                                                    readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number"
                                                    placeholder="Left Axis"
                                                    value={cExam.subjective_refraction.left_axis}
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                        <td className="border-x p-1">
                                            
                                            <span className="flex flex-col">
                                                <input 
                                                    type="number"
                                                    placeholder="Right VA"
                                                    value={cExam.subjective_refraction.right_va}
                                                    readOnly
                                                ></input>
                                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                                <input 
                                                    type="number"
                                                    placeholder="Left VA"
                                                    value={cExam.subjective_refraction.left_va}
                                                    readOnly
                                                ></input>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details" value={cExam.subjective_refraction.notes} readOnly></textarea>
                        </div>}
                        {cExam.near_addition && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Near Addition</h1>
                            <div className="flex flex-row">
                                <table className="table-fixed divide-y w-1/2 divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <thead className="w-full divide-y shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <tr className="">
                                            <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider"></th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Right
                                            </th>
                                            <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                                                Left
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-x p-1 font-bold"> Add </td>
                                            <td className="border-x p-1"> 
                                                <input
                                                type="number"
                                                placeholder="Right Add"
                                                value={cExam.near_addition.right_add}
                                                readOnly
                                                ></input>
                                            </td>
                                            <td className="border-x p-1">
                                                <input
                                                    type="number"
                                                    placeholder="Left Add"
                                                    value={cExam.near_addition.left_add}
                                                    readOnly
                                                ></input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-x p-1 font-bold"> NVA </td>
                                            <td className="border-x p-1"> 
                                                <input
                                                    type="text"
                                                    placeholder="Right NVA"
                                                    value={cExam.near_addition.right_nva}
                                                    readOnly
                                                ></input>                                                    
                                            </td>
                                            <td className="border-x p-1">
                                                <input
                                                    type="text"
                                                    placeholder="Left NVA"
                                                    value={cExam.near_addition.left_nva}
                                                    readOnly
                                                ></input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-x p-1 font-bold"> Range </td>
                                            <td colSpan={2} className="w-full p-1 text-center">
                                                <input 
                                                className="w-4/5 flex ml-3"
                                                    type="text"
                                                    value={cExam.near_addition.range}
                                                    readOnly
                                                ></input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <textarea className="w-1/2 mx-auto p-1.5" placeholder="Other Details" value={cExam.near_addition.notes}readOnly></textarea>
                            </div>
                        </div>}
                        {cExam.muscle_balance && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Muscle Balance</h1>  
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Distance" value={cExam.muscle_balance.distance}readOnly></textarea>
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Near" value={cExam.muscle_balance.near} readOnly></textarea>
                        </div>}
                        {cExam.fixation_disparity && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Fixation Disparity</h1>
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Distance" value={cExam.fixation_disparity.distance} readOnly></textarea>
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Near" value={cExam.fixation_disparity.near} readOnly></textarea>
                        </div>}
                        {cExam.visual_fields && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Visual Fields</h1>
                            <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Visual Fields" value={cExam.visual_fields.notes}readOnly></textarea>
                        </div>}
                        {cExam.ocular_examination && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Ocular Examination</h1>
                            <ChartRender charting={cExam.ocular_examination?.charting || []}  />
                            <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Other Details" value={cExam.ocular_examination.notes} readOnly></textarea>
                        </div>}
                        {cExam.additional_investigations && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Additional Investigations</h1>
                            <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Additional Investigations" value={cExam.additional_investigations.notes} readOnly></textarea>
                        </div>}
                        {cExam.patient_management && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Patient Management</h1>
                            <textarea className="w-full mx-auto h-32 p-1.5" placeholder="Patient Management" value={cExam.patient_management.notes} readOnly></textarea>
                        </div>}

                        {cExam.final_prescription && <div className="w-full border-t border-x border-stone-800 relative">
                            <h1 className="bg-stone-300 text-center font-semibold text-lg">Final Prescription</h1>
                            <PrescriptionTable prescriptions={cExam.final_prescription.charting} />
                            <textarea className="w-full mx-auto h-16 p-1.5" placeholder="Other Details" value={cExam.final_prescription.notes} readOnly></textarea>
                        </div>}


                        <div className="border-t border-stone-800"> </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export async function ExaminationLoader({ params }: { params: Params<string> }) {
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    if (!async_authorised_request) {
        return { error: "Not authorised", status: 401 };
    }

    if (!params.id || params.id.length != 24) {
        return { error: "Invalid Patient", status: 400 };
    }

    return Promise.all([
        async_authorised_request("GET", `patient/details/${params.id}`, undefined),
        async_authorised_request("GET", `examination/byPatientId/${params.id}`, undefined),
    ])
        .then((values) => {
            return { patient: values[0].patient, examinations: values[1].examinations };
        })
        .catch((error) => {
            return { error: error.message, status: 500 };
        });
}
