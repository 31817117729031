import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../context/LoginContext";
import { IPopulatedPatient } from "../types/populated.types";
import { useEffect, useState } from "react";
import { useNavbarStore } from "../context/NavbarContext";
import { GetPatientNav } from "../patientHomepage/patientNav";
import Chart from "./Chart";
import ChartRender from "./ChartRender";
import { IChart, IChartPlot } from "../types/Charting.types";
import { format } from "date-fns";

export default function PatientChart(){
    const {patient, charts} = useLoaderData() as {patient: IPopulatedPatient, charts: any};
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);
    const [chartData, setChartData] = useState<IChartPlot[]>([]);
    const [charting, setCharting] = useState<IChart[]>(charts || []);
    
    const setContent = useNavbarStore(state => state.setContent);

    useEffect(() => {
        if (patient) {
            setContent(GetPatientNav(patient._id, "Chart"));
        }
        return () => setContent([]);
    }, [patient]);

    function createCharting(){
        if (chartData.length === 0) return;
        async_authorised_request("POST", `charts/new`, {charting:chartData, patient_id:patient._id}).then((res) => {
            setCharting([res.chart, ...charting]);
            setChartData([])
        }).catch((_) => {
            alert("Error saving chart");
        });
    }
    
    return <div className="">
            <h1 className="text-2xl m-2">Charting - {patient.surname}, {patient.forename}</h1>

            <Chart getCharting={chartData} setCharting={setChartData} save={createCharting}/>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-4">
                {charting.map((chart) => <div onDoubleClick={_=>setChartData(chart.charting)} key={chart._id} className="relative m-1 p-1 bg-stone-300 text-center font-semibold rounded-sm"><h3>{chart.created_by?.forename} {chart.created_by?.surname}</h3><h3>{format(new Date(chart.created_at), "dd/MM/yy HH:mm")}</h3><ChartRender  charting={chart.charting}/></div>)}
            </div>
           
       
        </div>
}

export function PatientChartLoader({params} : {params: Params<string>}){
    const {async_authorised_request} = useLoginStore.getState();

    if (!async_authorised_request) return {error: "Not logged in", status: 401};

    const patient_id = params.id;
    if (!patient_id || patient_id.length !== 24) return {error: "Invalid patient id", status: 400};

    return Promise.all([
        async_authorised_request("GET", `patient/lean/${patient_id}`, undefined),
        async_authorised_request("GET", `charts/patient/${patient_id}`, undefined)
    ]).then((request) => {
        return {patient: request[0].patient, charts: request[1].charts}//requests[1].chart};
    }).catch((error) => {
        return {error: error.message, status: 500};
    });
}