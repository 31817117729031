export interface IProgramFlag {
    _id: string;
    name: string;
}

export interface IPatientNotes{
    note_plain: string;
    note_json: string;
    created_at: number;
    created_by: IAccount;
    _id: string;
}

export interface IAccount{
    _id: string;
    username: string,
    password: string,
    salt: string,
    created: number,
    last_login: number,
    forename: string,
    surname: string,
    email: string,
    permission_group: number,
    ValidatePassword(password: string): boolean,
}

export interface IProgramScheme {
    _id: string;
    name: string;
}

export interface IProgramStatus{
    _id: string,
    name: string;
}

export interface IProgramEventReason{
    _id: string;
    name: string;
    description: string;
    colour: string;
}

export interface IEventBook{
    _id:string,
    title: string,
    is_active: boolean,
}

export enum SocialPlatformEnum {
    "facebook" = "Facebook",
    "twitter" = "X",
    "instagram" = "Instagram",
    "linkedin" = "LinkedIn",
    "snapchat" = "SnapChat",
    "tiktok" = "TikTok",
    "youtube" = "YouTube",
    "twitch" = "Twitch",
    "whatsapp" = "WhatsApp",
    "telegram" = "Telegram",
    "signal" = "Signal"
}


export interface IRecallReason{
    _id: string,
    name: string,
    default_offset: Date
}