import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../context/LoginContext";
import { FormEvent, useEffect, useState } from "react";
import { ITelephone } from "../../types/Patient.types";
import { useNavbarStore } from "../../context/NavbarContext";
import { GetPatientNav } from "../../patientHomepage/patientNav";
import { ISMS } from "../../types/SMS.types";
import { format } from "date-fns/format";

export default function SMS(){
    const LoaderData = useLoaderData() as {sms: any[], patient: any, error: string};
    const [SMSLog, setSMSLog] = useState(LoaderData.sms || []);
    const numbers = LoaderData.patient.telephone.filter((t:ITelephone) => !t.landline).map((t: ITelephone) => t.number);
    const [newSMS, setNewSMS] = useState({message:"", phone_number:(numbers ? numbers[0] : "")});
    const setContent = useNavbarStore(state => state.setContent);
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);

    useEffect(() => {
        if (LoaderData.patient){
            setContent(GetPatientNav(LoaderData.patient._id, "SMS"))
        }
        return () => {
            setContent([])
        }
    }
    , [LoaderData.patient])

    const create_sms = (e: FormEvent<Element>) => {
        e.preventDefault();

        if (!newSMS.message || !newSMS.phone_number){
            return;
        }

        setNewSMS({message:"", phone_number:(numbers ? numbers[0] : "")})

        async_authorised_request("POST", "sms/create", {
            patient: LoaderData.patient._id,
            message: newSMS.message.trim(),
            phone_number: newSMS.phone_number,
            scheduled_at: Date.now()
        }).then((r) => {
            if (r.status === 200){
                console.log(r.debug_service_response)
                setSMSLog([r.sms, ...SMSLog])
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    return (
        <div className="bg-stone-200 m-2 rounded-lg p-1 space-y-3">
            <h1 className="text-3xl m-2 font-semibold text-stone-900">SMS</h1>
            <hr className="border border-stone-400 "/> 
            <div className="space-x-1 bg-stone-300 max-w-lg mx-auto">
                <form className="flex-col flex max-w-lg mx-auto p-4 space-y-2 rounded-sm relative" onSubmit={create_sms}>
                    <h1 className="text-2xl">Send SMS</h1>
                    <label className="text italic absolute top-3 right-4">Cost: {Math.ceil(newSMS.message.trim().length/160)}</label>
                    <select disabled={newSMS.phone_number === ""} className="formi" id="phone_number" name="phone_number" value={newSMS.phone_number} onChange={e=>setNewSMS({...newSMS, phone_number: e.target.value})}>
                        <option hidden value="">No Number Available</option>
                        {numbers.map((n: string) => <option key={n} value={n}>{n}</option>)}
                    </select>
                    <textarea className="formi" rows={8} id="message" name="message" value={newSMS.message} onChange={e=>setNewSMS({...newSMS, message: e.target.value})}></textarea>
                        <label className="text italic">Characters: {newSMS.message.trim().length}</label>
                    <button type="submit" disabled={!newSMS.phone_number || !newSMS.message} className="text-lg font-semibold  disabled:bg-stone-400 bg-stone-400 hover:bg-stone-500 px-2 py-1  rounded-sm">Create SMS</button>
                </form>
            </div>
            <hr className="border border-stone-400"/> 
            <div>
                <h1 className="text-2xl">SMS Log</h1>
                <div className="overflow-x-auto">
                    {SMSLog.length ? <table className="text-center table-fixed w-full">
                        <thead>
                            <tr>
                                <th className="w-1/5">Phone Number</th>
                                <th className="w-2/5">Message</th>
                                <th className="w-1/5">Date</th>
                                <th className="w-1/5">Status (Token)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {SMSLog.map((sms: ISMS) => (
                                <tr key={sms._id}>
                                    <td>{sms.phone_number}</td>
                                    <td className="w-1/3 text-ellipsis truncate">{sms.message}</td>
                                    <td>{format(new Date(sms.scheduled_at), "dd/MM/yy HH:mm")}</td>
                                    <td>{sms.status === "pending" ? "Pending" : sms.status === "sent" ? "Sent" : "Failed"} ({sms.cost?sms.cost:"?"})</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>: <h2 className="text-lg italic">No Previous SMS</h2>}
                </div>
            </div>
        </div>
    )
}

export function SMSLoader({params}:{params:  Params<string>}){
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    return Promise.all([
        async_authorised_request("GET", `sms?patient=${params.id}`, undefined),
        async_authorised_request("GET", `patient/details/${params.id}`, undefined)
    ]).then(([r1, r2]) => {
        if (r1.status !== 200 || r2.status !== 200){
            return {error: "Failed to load data"}
        }
        return {sms:r1.sms, patient:r2.patient}
    }).catch((error) => {
        return {error: error.message}
    })
}