import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import './index.css'
import Login from './components/login/Login.tsx';
import PatientHomepage, { PatientHomepageLoader } from './components/patientHomepage/PatientHomepage.tsx';
import Error from './components/error/Error.tsx';
import ContextLoader from './components/context/ContextLoader.tsx';
import PatientSearch from './components/patientSearch/PatientSearch.tsx';
import Diary, { DiaryLoader } from './components/diary/Diary.tsx';
import PatientNotes, { PatientNotesLoader } from './components/patientNotes/PatientNotes.tsx';
import Reminder, { ReminderLoader } from './components/reminder/Reminder.tsx';
import PatientBalance, { PatientBalanceLoader } from './components/patientBalance/PatientBalance.tsx';
import Index from './components/index/Index.tsx';
import PatientImages, { PatientImagesLoader } from './components/patientImages/PatientImages.tsx';
import PatientChart, { PatientChartLoader } from './components/patientChart/PatientChart.tsx';import Examination, { ExaminationLoader } from './components/examination/Examination.tsx';
import PatientSMS, {SMSLoader as PatientSMSLoader} from './components/patient/sms/SMS.tsx';
import { ReportIndex } from './components/report/index/ReportIndex.tsx';
import ReportSMS, { ReportSMSLoader } from './components/report/sms/ReportSMS.tsx';
import ReportRecall, { ReportRecallLoader } from './components/report/recall/ReportRecall.tsx';
import { GoogleOAuthProvider } from '@react-oauth/google';


const router = createBrowserRouter(
  createRoutesFromElements(
    [
      <Route path='/login' element={<Login/>}/>,
      <Route path='' element={<App/>} errorElement={<Error/>} >
        <Route path='/reminders' element={<Reminder/>} loader={ReminderLoader}/>
        
        <Route path='/diary' element={<Diary/>} loader={DiaryLoader}/>
                
        <Route path='/patient/search' element={<PatientSearch/>}/>
        
        <Route path='/patient/:id'>
          <Route path='reminders' element={<Reminder/>} loader={ReminderLoader}/>
          <Route path='notes' element={<PatientNotes/>} loader={PatientNotesLoader} />
          <Route path="balance" element={<PatientBalance/>} loader={PatientBalanceLoader}/>
          {/* <Route path='medical' element={<PatientMedical/>} loader={PatientMedicalLoader}/> */}
          <Route path="images" element={<PatientImages/>} loader={PatientImagesLoader}/>
          <Route path='chart' element={<PatientChart/>} loader={PatientChartLoader} />
          {/* <Route path="prescriptions" element={<PatientPrescription/>} loader={PatientPrescriptionLoader}/> */}
          <Route path="examination"  element={<Examination/>} loader={ExaminationLoader}/>
          <Route path="sms" element={<PatientSMS/>} loader={PatientSMSLoader}/>
          <Route index path='' element={<PatientHomepage/>} loader={PatientHomepageLoader} />
          <Route path='*' element={<Navigate to='/patient/search'/>} />
        </Route>

        <Route path="/report">
          <Route path='sms' element={<ReportSMS/>} loader={ReportSMSLoader}/>
          <Route path='recall' element={<ReportRecall/>} loader={ReportRecallLoader}/>
          <Route index element={<ReportIndex/>}/>
        </Route>

        <Route index element={<Index/>}/>
        <Route path='*' element={<Navigate to='/'/>} />
      </Route>
    ]
  )
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ContextLoader/>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <RouterProvider router={router}/>
      </GoogleOAuthProvider>;
  </React.StrictMode>,
)
